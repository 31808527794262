// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#display-columns span {
  color: transparent;
}

#display-columns span::before {
  color: #000;
  content: "Hide or show columns "
}
`, "",{"version":3,"sources":["webpack://./src/components/DisplayColumns/DisplayColumns.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX;AACF","sourcesContent":["#display-columns span {\n  color: transparent;\n}\n\n#display-columns span::before {\n  color: #000;\n  content: \"Hide or show columns \"\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
