import { Input } from '@hexa-ui/components';
import * as S from './ReportName.styles';
import { IReportNameProps } from 'types/schedule';
import { useCallback } from 'react';

export const ReportName: React.FC<IReportNameProps> = ({
  onChange,
  value,
  placeholder = ' ',
  ...props
}): JSX.Element => {
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value);
    },
    [onChange]
  );

  return (
    <S.Container>
      <Input
        width="100%"
        id="input-with-label"
        placeholder={placeholder}
        data-testid="report-container"
        onChange={handleChange}
        value={value}
        required
        {...props}
        maxLength={50}
      />
    </S.Container>
  );
};
