import { styled } from '@hexa-ui/theme';

export const WrapperHeader = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '$4',
});

export const WrapperAlignTitle = styled('div', {
  width: '100%',
  h1: {
    fontSize: '2rem',
  },
});

export const Description = styled('div', {
  margin: '$2 0',
  '&': {
    fontFamily: '$paragraph',
  },
});

export const WrapperAlignButtons = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
  width: '100%',
  gap: '$4',
  marginTop: '1.5rem',

  '@media (max-width: 960px)': {
    flexDirection: 'column',
    width: '50%',
    gap: '$2',
    marginTop: '3.5rem',
  },
});
