import { Table } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

import { IPaginationConfig, TableRequestProps } from 'types/tableRequest';
import imageEmpty from '@assets/images/not-found.svg';
import * as S from './TableRequest.styles';
import './TableRequest.css';
import { translateArray } from 'utils/translateArray';
import { useMemo } from 'react';

const TableRequest = ({
  type,
  data,
  loading,
  onSort,
  totalResults,
  filtersPagination,
  setFiltersPagination,
  isError,
  commonColumns,
}: TableRequestProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const getPaginationConfig = (pageRequest: string): IPaginationConfig => ({
    total: totalResults === 0 ? 1 : totalResults,
    pageSizeOptions: [5, 10, 25, 50],
    current: filtersPagination.page + 1,
    pageSize: filtersPagination.pageSize,
    showQuantityIndicator: true,
    showPageSizeSelector: true,
    disabled: totalResults === 0,
    pageSizeOptionsIntl: (option: number): string => {
      return formatMessage(
        { id: `${pageRequest}.pagination.pageSizeOptions` },
        { options: option }
      );
    },
    onChange: (page: number, pageSize: number): void => {
      setFiltersPagination((prevState: IPaginationConfig) => ({
        ...prevState,
        page: page - 1,
        pageSize,
      }));
    },
  });

  const translatedCommonColumns = useMemo(
    () => translateArray(commonColumns, formatMessage),
    [commonColumns, formatMessage]
  );

  const infoTable = {
    Resolved: {
      emptyMessage: formatMessage({ id: 'resolvedRequestsPage.emptyMessage' }),
      dataTestId: 'transactions-table',
      columns: translatedCommonColumns,
      pagination: getPaginationConfig('resolvedRequestsPage'),
    },
  };

  return (
    <S.Container
      className={`table-request-container ${
        totalResults === 0 ? 'pagination-container-empty' : ''
      }`}
    >
      <Table
        fixedHeader
        onSort={onSort}
        loadingMessage=""
        emptyMessage={
          isError ? (
            <S.EmptyStateWrapper
              data-testid="empty-state"
              description={formatMessage({
                id: 'resolvedRequestsPage.errorsMessages.resultNotFound',
              })}
              customIlustration={{
                alt: formatMessage({ id: 'resolvedRequestsPage.altNoRecordsFound' }),
                src: imageEmpty,
              }}
            />
          ) : (
            <span>{infoTable[type].emptyMessage}</span>
          )
        }
        tableHeight="100%"
        tableWidth="100%"
        data={data}
        loading={loading}
        data-testid={infoTable[type].dataTestId}
        onRow={infoTable[type].onRow}
        columns={infoTable[type].columns}
        pagination={{
          ...infoTable[type].pagination,
          quantityIndicatorIntl: formatMessage({ id: 'pagination.of' }),
        }}
      />
    </S.Container>
  );
};
export default TableRequest;
