import { styled } from '@hexa-ui/theme';

export const SearchFieldWrapper = styled('div', {
  '&.search-field-wrapper-input': {
    'div[data-testid="search-field-wrapper"] button[aria-label="Clear input"]': {
      backgroundColor: 'rgba(20, 20, 20, 0.16)',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '$interfaceSurfacePrimary',
      },
    },
    'div[data-testid="search-field-wrapper"] button[role="combobox"]': {
      marginTop: '0',
    },
  },
});
