import { gql } from 'graphql-request';

export const getCreditdataQuery = gql`
  query GetGatewayReportDataResolver($gatewayReportInput: GatewayReportInput!) {
    getGatewayReportDataResolver(GatewayReportInput: $gatewayReportInput) {
      content {
        accountId
        buyerId
        invoiceNumber
        invoiceStatus
        invoiceDate
        sellerId
        invoiceValue
        orderNumber
        orderStatus
        orderValue
        paymentMethod
        paymentMethodCode
        paymentStatus
        paymentValue
        paymentId
        paymentDate
        beneficiaryId
        beneficiaryName
        paymentMethodFee
        commissionFee
      }
      pagination {
        page
        totalPages
        totalRecords
      }
      userViewerType
    }
  }
`;
