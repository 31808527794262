import { gql } from '@apollo/client';

export const CREATE_SCHEDULE_REPORT = gql`
  mutation CreateSchedule($input: CreateScheduleInputType!) {
    createSchedule(input: $input) {
      id
      emailAddress
      downloadHour
      fileType
      status
      userRequest
      country
      columnFilters
      description
      deleted
      creationDate
      updateDate
      searchFilters {
        buyerId
        invoiceNumber
        invoiceStatus
        orderNumber
        paymentDue
        paymentMethod
        paymentStatus
      }
    }
  }
`;
