import { useQuery } from '@tanstack/react-query';
import { GraphQLClient, ClientError } from 'graphql-request';

import { ErrorBody, IFetch } from './useFetch.types';
import { useState } from 'react';

const graphQlClient = (
  apiUrl: string,
  authorization: string,
  country?: string,
  requestTraceId?: string,
  authorizationHub?: string
) => {
  return new GraphQLClient(apiUrl, {
    headers: {
      Authorization: authorization,
      country,
      requestTraceId,
      authorizationHub: authorizationHub,
    },
  });
};

export function useFetch(fetchParams: IFetch | null) {
  const isEnabled = fetchParams !== null;
  const client = isEnabled
    ? graphQlClient(
        fetchParams.graph,
        fetchParams.authorization,
        fetchParams.country,
        fetchParams.requestTraceId,
        fetchParams.authorizationHub
      )
    : null;
  const [errorBody, setErrorBody] = useState<ErrorBody | null>(null);
  const query = useQuery({
    keepPreviousData: fetchParams?.settings?.keepPreviousData ?? false,
    staleTime: fetchParams?.settings?.staleTime ?? 5000,
    enabled: isEnabled && (fetchParams?.settings?.enabled ?? true),
    cacheTime: fetchParams?.settings?.cacheTime ?? 300000,
    retry: 0,
    refetchOnWindowFocus: false,
    queryKey: fetchParams?.key ?? [],
    onError: (error) => {
      let responseBody = null;
      if (error instanceof ClientError && error.response) {
        responseBody = (
          error.response.errors?.[0]?.extensions?.response as { body?: unknown } | undefined
        )?.body;
      } else {
        responseBody = 'An unexpected error occurred';
      }
      setErrorBody(responseBody);
    },
    queryFn: async () => {
      if (!client) {
        throw new Error('GraphQL client is not initialized.');
      }
      return await client.request(fetchParams.query, fetchParams.variables);
    },
  });

  return {
    ...query,
    errorBody,
  };
}
