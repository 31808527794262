import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DatePicker } from '@hexa-ui/components';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import * as S from './DateFilter.styles';
import { formatDate, formatPlaceholderDateInput, parseDateString } from 'utils/date';
import { getLocale } from 'utils/locale';
import { useFilter } from 'contexts/FilterContext';

export function DateFilter(): JSX.Element {
  const { preferredLanguage } = usePreferredLanguageV2();
  const { formatMessage } = useIntl();
  const { fromCreatedAtValue, setFromCreatedAtValue, toCreatedAtValue, setToCreatedAtValue } =
    useFilter();

  const [startDateInput, setStartDateInput] = useState<Date[]>([]);
  const [endDateInput, setEndDateInput] = useState<Date[]>([]);

  const handleDateChange =
    (setter: React.Dispatch<React.SetStateAction<Date[]>>) => (date: Date[]) => {
      setter(date);
    };

  useEffect(() => {
    const parsedStartDate = fromCreatedAtValue
      ? parseDateString(fromCreatedAtValue, preferredLanguage)
      : null;
    const parsedEndDate = toCreatedAtValue
      ? parseDateString(toCreatedAtValue, preferredLanguage)
      : null;

    setStartDateInput(parsedStartDate ? [parsedStartDate] : []);
    setEndDateInput(parsedEndDate ? [parsedEndDate] : []);
  }, [fromCreatedAtValue, toCreatedAtValue]);

  useEffect(() => {
    if (startDateInput?.[0]) {
      setFromCreatedAtValue(formatDate(startDateInput[0], preferredLanguage));
    }

    if (endDateInput?.[0]) {
      setToCreatedAtValue(formatDate(endDateInput[0], preferredLanguage));
    }
  }, [startDateInput, endDateInput]);

  return (
    <S.Container>
      <S.Description>{formatMessage({ id: 'filter.description' })}</S.Description>
      <S.InputWrapper>
        <S.DatePickerInputWrapper data-testid="end-date-input" className="datePicker-1">
          <S.DatePickerWrapper>
            <S.Label>{formatMessage({ id: 'filter.startDate' })}</S.Label>
            <DatePicker
              id="start-date"
              value={startDateInput}
              onChange={handleDateChange(setStartDateInput)}
              onSave={handleDateChange(setStartDateInput)}
              mode="single"
              customLocale={getLocale(preferredLanguage)}
              width="220px"
              placeholder={formatPlaceholderDateInput(preferredLanguage)}
            />
          </S.DatePickerWrapper>
        </S.DatePickerInputWrapper>
        <S.DatePickerInputWrapper data-testid="end-date-input" className="datePicker-2">
          <S.DatePickerWrapper>
            <S.Label>{formatMessage({ id: 'filter.endDate' })}</S.Label>
            <DatePicker
              id="end-date"
              value={endDateInput}
              onChange={handleDateChange(setEndDateInput)}
              mode="single"
              customLocale={getLocale(preferredLanguage)}
              width="220px"
              placeholder={formatPlaceholderDateInput(preferredLanguage)}
            />
          </S.DatePickerWrapper>
        </S.DatePickerInputWrapper>
      </S.InputWrapper>
    </S.Container>
  );
}

export default DateFilter;
