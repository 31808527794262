import { useContext } from 'react';
import { ModalContext } from 'contexts';
import { DownloadModal, ScheduledModal, ScheduleModal } from 'pages/Reports/components';

import * as S from './ModalGlobal.styles';

const ModalGlobal = () => {
  const { scheduleModal, downloadModal, scheduledModal } = useContext(ModalContext);

  return (
    <div data-testid="modal-testing-id">
      {downloadModal.isOpen && (
        <S.ModalContainer
          open={downloadModal.isOpen}
          data-testid="download-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DownloadModal />
        </S.ModalContainer>
      )}

      {scheduledModal.isOpen && (
        <S.ModalContainer
          open={scheduledModal.isOpen}
          data-testid="scheduled-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ScheduledModal />
        </S.ModalContainer>
      )}

      {scheduleModal.isOpen && (
        <S.ModalContainer
          open={scheduleModal.isOpen}
          data-testid="schedule-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ScheduleModal />
        </S.ModalContainer>
      )}
    </div>
  );
};

export default ModalGlobal;
