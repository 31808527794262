export enum UserViewerEnum {
  VIEWER_3P = 'VIEWER_3P',
  VIEWER_GLOBAL = 'VIEWER_GLOBAL',
  VIEWER_ADM = 'VIEWER_ADM',
}

export enum FilterLabels {
  PAYMENT_STATUS = 'Payment Status',
  ORDER_STATUS = 'Order Status',
  INVOICE_STATUS = 'Invoice Status',
}
