import { styled } from '@hexa-ui/theme';

export const WrapperCustomButton = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '& button': {
    color: '#fff',
    backgroundColor: '$neutral90',
  },

  '& button:hover': {
    color: 'yellow',
    backgroundColor: '$neutral90',
  },
});

export const SubmenuWrapper = styled('ul', {
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  zIndex: 1000,
  top: '45px',
  left: 0,
  marginTop: '0px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '0',

  '@media (max-width: 960px)': {
    right: 0,
  },
});

export const SubmenuItem = styled('li', {
  fontFamily: 'Work Sans',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  color: '$neutral90',
  cursor: 'pointer',
  height: '56px',

  '&:hover': {
    fontWeight: 'bold',
    backgroundColor: '$neutral10',
  },

  '& svg': {
    marginRight: '8px',
  },

  '&:first-child': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },

  '&:last-child': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
});
