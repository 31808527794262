export enum Countries {
  CO = 'CO',
  DO = 'DO',
  EC = 'EC',
  MX = 'MX',
}

export enum PaymentCurrency {
  CO = 'COP',
  DO = 'DOP',
  EC = 'EC',
  MX = 'MXN',
}

export enum OrderStatusLabels {
  PARTIAL_DELIVERY = 'PARTIAL DELIVERY',
  IN_TRANSIT = 'IN TRANSIT',
}

export enum IdGroupCheckBox {
  order = 'order',
  invoice = 'invoice',
  payment = 'payment',
}
