import { styled } from '@hexa-ui/theme';

const MAX_DROPDOWN_HEIGHT = '200px';
const BORDER_RADIUS = '0.5rem';

const commonStyles = {
  fontFamily: 'Work Sans',
};

export const StyledForm = styled('form', {
  width: '100%',
  height: '100%',
});

export const InputWrapper = styled('section', {
  margin: '4 0',
});

export const Dropdown = styled('div', {
  ...commonStyles,
  position: 'relative',
  width: '100%',
});

export const DropdownLabel = styled('div', {
  ...commonStyles,
  fontSize: '0.875',
  lineHeight: '20px',
  marginBottom: '0.5rem',
  fontWeight: '600',
});

export const DropdownButton = styled('button', {
  ...commonStyles,
  width: '100%',
  height: '3rem',
  padding: '1rem',
  fontWeight: '500',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  color: 'rgba(20, 20, 20, 0.56)',
  textAlign: 'left',
  fontSize: '1rem',
  borderRadius: BORDER_RADIUS,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:focus': {
    border: '1px solid #000',
  },
});

export const DropdownMenu = styled('div', {
  ...commonStyles,
  position: 'absolute',
  width: '100%',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  zIndex: 1000,
  maxHeight: MAX_DROPDOWN_HEIGHT,
  overflowY: 'auto',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

  borderRadius: BORDER_RADIUS,
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
  // Firefox scrollbar styles
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1)',
  // Remover a regra que esconde a barra de rolagem
  msOverflowStyle: 'auto', // IE and Edge
});

export const OptionWrapper = styled('div', {
  padding: '10px',
  cursor: 'pointer',
  // '&.selected': {
  //   backgroundColor: 'rgba(243, 235, 252, 0.3)',
  // },
  '&:hover': {
    backgroundColor: 'rgba(243, 235, 252, 0.3)',
  },
});

export const Title = styled('div', {
  fontSize: '1rem',
  lineHeight: 'medium',
});

export const Description = styled('div', {
  color: 'rgba(20, 20, 20, 0.56)',
  fontSize: '0.75rem',
  lineHeight: 'small',
  marginTop: '2',
});
