import {
  Button,
  Divider,
  IconButton,
  Modal as ModalComponent,
  Toast as ToastComponent,
} from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { IScheduleProps } from 'types/schedule';
import { getSelectedFilters } from 'utils/getSelectedFilters';
import { useUserEmail } from 'utils/useUserEmail/useUserEmail';
import * as S from './ModalSendNow.styles';
import { Summary } from './components';
import { useModalSendNow } from './hooks/useModalSendNow';

export const ModalSendNow = ({ isOpen, onClose }: IScheduleProps): JSX.Element => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement>(null);
  const { formatMessage } = useIntl();
  const userEmail = useUserEmail();

  const handleCloseModal = () => {
    onClose(false);
  };

  const { createSchedule, loading, toast, handleToastOpenChange } =
    useModalSendNow(handleCloseModal);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createSchedule();
  };

  const { Root: Modal } = ModalComponent;
  const { Root: Toast } = ToastComponent;

  const leftFilters = getSelectedFilters({ column: 'left', modalType: 'ModalSendNow' }) || [];
  const rightFilters = getSelectedFilters({ column: 'right', modalType: 'ModalSendNow' }) || [];

  return (
    <>
      <Toast {...toast} showCloseButton onClose={() => handleToastOpenChange(false)} />
      <S.ModalWrapper data-testid="schedule">
        <Modal
          open={isOpen}
          actions={null}
          title={
            <S.ContainerHeader>
              <S.Title>{formatMessage({ id: 'resolvedRequestsPage.reportSendNow.title' })}</S.Title>
              <IconButton onClick={handleCloseModal} size="small" variant="tertiary" icon={X} />
            </S.ContainerHeader>
          }
          aria-describedby="modal-description"
        >
          <S.StyledForm onSubmit={submitHandler}>
            <S.Description>
              {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.description' })}
              <strong>{userEmail}</strong>
            </S.Description>
            <S.WrapperContent>
              <S.WrapperLeftBox>
                <S.Label size="H4">
                  {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.filters' })}
                </S.Label>
                <S.WrapperFilters>
                  {leftFilters.map((filter) => (
                    <Summary
                      title={filter.title}
                      description={filter.description}
                      key={filter.id}
                    />
                  ))}
                </S.WrapperFilters>
              </S.WrapperLeftBox>
              <Divider orientation="vertical" css={S.widthStyles} />
              <S.WrapperLeftBox>
                <S.WrapperFilters>
                  {rightFilters.map((filter) => (
                    <Summary
                      title={filter.title}
                      description={filter.description}
                      key={filter.id}
                    />
                  ))}
                </S.WrapperFilters>
              </S.WrapperLeftBox>
            </S.WrapperContent>
            <S.footerContainer>
              <S.Box>
                <Button size="medium" variant="secondary" onClick={handleCloseModal}>
                  {formatMessage({ id: 'resolvedRequestsPage.reportSendNow.goBackButton' })}
                </Button>
                <Button
                  ref={firstInteractiveElementRef}
                  size="medium"
                  disabled={loading}
                  type="submit"
                  isLoading={loading}
                >
                  {formatMessage({ id: 'resolvedRequestsPage.reportSendNow.sendButton' })}
                </Button>
              </S.Box>
            </S.footerContainer>
          </S.StyledForm>
        </Modal>
      </S.ModalWrapper>
    </>
  );
};
