import { useSelector } from 'react-redux';
import { Select } from '@hexa-ui/components';
import { Columns } from '@hexa-ui/icons';

import { columnsHideShowDropDown } from 'constants/columnsHideShowDropDown';
import { managerTypeUser } from 'constants/managerUserViewerType';
import { reportSelectedUserViewer } from 'store/selectors/report.selectors';
import { DisplayColumnsProps } from './types';
import './DisplayColumns.styles.css';

export const DisplayColumns = ({ columns, onChange }: DisplayColumnsProps) => {
  const onChangeHandle = (value: string[]) => {
    onChange(value);
  };

  const { Root, Option } = Select;
  const typeUser = useSelector(reportSelectedUserViewer);

  const allowedColumns =
    managerTypeUser.find((userType) => typeUser.includes(userType.type))?.key || [];

  const filteredColumns = columnsHideShowDropDown.filter((column) =>
    allowedColumns.includes(column.key)
  );
  const singleFilteredColumnDisabled = filteredColumns.length === 1;

  return (
    <Root
      id="display-columns"
      icon={<Columns />}
      multiple
      placeholder="Hide or show columns"
      value={columns.map((column) => column.key)}
      displayText="columns selected"
      onChange={(value) => onChangeHandle(value)}
    >
      {filteredColumns.map((column) => (
        <Option key={`${column.key}`} value={column.key} disabled={singleFilteredColumnDisabled}>
          {column.label}
        </Option>
      ))}
    </Root>
  );
};
