import MessageMap from 'i18n/i18n.d';

const es: MessageMap = {
  Commons: {
    actions: 'Acciones',
    edit: 'Editar',
    delete: 'Remover',
    portal: 'Portal De BEES Pay ',
  },
  ErrorMessages: {
    DATA_NOT_FOUND: 'Os dados não foram carregados.',
    ERROR_FETCH_DATA:
      'Ocorreu um erro ao tentar buscar os dados. Atualize a página e tente novamente.',
  },
  PageTitles: {
    WORKFLOW: 'Fluxo de Trabalho',
    CREATE: 'Novo fluxo de trabalho',
    PAYMENTS: 'Pagos',
    TRANSACTIONS: 'Transacciones',
    RECONCILIATION: 'Reconciliation',
  },
  downloadModal: {
    titleHeader: 'Relatório por email',
    description: 'O arquivo contendo os dados filtrados será enviado para o seu email corporativo.',
    fieldEmail: 'Seu email',
    fieldFileFormat: 'Formato',
    sendButton: 'Enviar',
    cancelButton: 'Voltar',
  },
  scheduleModal: {
    titleHeader: 'Agendar envio',
    description: 'Receba o arquivo em seu email',
    fieldFileFormat: 'Formato',
    fieldFrequency: 'Frequência',
    subFieldFrequency: 'Recorre toda semana em:',
    fieldDelivery: 'Horário de envio',
    fieldEmail: 'Email',
    sendButton: 'Agendar',
    cancelButton: 'Cancelar',
  },
  resolvedRequestsPage: {
    sendReport: 'Enviar informe',
    sendNow: 'Enviar ahora',
    schedule: 'Agendar',
    showScheduled: 'Mostrar informes programados',
    newScheduled: 'Nuevo horario',
    manageScheduled: 'Administrar informes programados',
    resolvedRequest: 'Resolved requests',
    description:
      'Accede a la información de pago y envía informes filtrados a tu correo electrónico.',
    results: 'Showing {resultsNumber} results',
    altNoRecordsFound: 'Illustration representing the absence of results in a search.',
    title: 'Title',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    vendor: 'Vendor',
    country: 'Country',
    tool: 'Tool',
    id: 'ID',
    requester: 'Requester',
    approver: 'Approver',
    pagination: { pageSizeOptions: 'Artículos por página: {options}' },
    errorsMessages: {
      resultNotFound: 'No se encontraron resultados. Intenta con otros números.',
      countryNotFound: 'País no encontrado. Actualiza la página.',
      errorFetchingData: 'Error al obtener datos.',
      errorConfigUser: 'Es posible que tu usuario no esté configurado correctamente.',
      errorCopyText: 'Failed to copy text to clipboard.',
    },
    editColumnsDropdown: {
      title: 'Editar columnas',
      selectAll: 'Seleccionar todo',
    },
    commom: {
      filter: 'Filtro',
      search: 'Buscar',
    },
    emptyMessage: 'No results found. Try again.',
    toastMessages: {
      paymentIdCopied: 'ID de pago copiado.',
    },

    scheduleNew: {
      emptyMessage:
        'No hay informes programados. Puedes programarlos en la página de Transacciones.',
      titleHeader: 'Programar entrega de informes',
      description:
        'El archivo que contiene los datos filtrados se enviará por correo electrónico a ',
      message:
        'The report file will contain payments started seven days before the selected weekday.',
      fieldFrequency: 'Frequency',
      subFieldFrequency: 'Sent every week on:',
      fieldDelivery: 'Hora de entrega',
      deliveryTime: 'Seleccionar una opción',
      fieldReportName: 'Report name',
      seller: 'Vendedor',
      method: 'Método',
      delete: 'Eliminar informe',
      deleteDescription:
        'El informe se eliminará permanentemente. Esta acción no se puede deshacer.',
      serviceProvider: 'Service provider',
      orderStatus: 'Estado del pedido',
      country: 'País',
      columns: 'Columnas',
      paymentStatus: 'Estado de pago',
      createDate: 'Creation date',
      filters: 'Filtros',
      report: 'Nombre del informe',
      placeholder: 'Credit card partially delivered',
      sendButton: 'Agendar',
      cancelButton: 'Volver',
      settings: 'El ajuste',
      frequency: 'Frecuencia',
      dayOfTheWeek: 'Día de la semana',
      successCreate: 'Entrega del informe programada.',
      errorCreate: 'No se puede programar el informe. Inténtalo de nuevo.',
      selectAoption: 'Seleccionar una opción',
      all: 'Todos',
      daysOfTheWeek: {
        name: {
          MONDAY: 'Todos los lunes',
          TUESDAY: 'Todos los martes',
          WEDNESDAY: 'Todos los miércoles',
          THURSDAY: 'Todos los jueves',
          FRIDAY: 'Todos los viernes',
        },
      },
      frequencyOptions: {
        daily: {
          title: 'Daily',
          description: 'With payments initiated the previous day.',
        },
        weekly: {
          title: 'Weekly',
          description: 'With payments initiated seven days prior to the selected weekday.',
        },
        every15Days: {
          title: 'Every 15 days',
          description: 'With payments initiated over the past fifteen days.',
        },
        monthly: {
          title: 'Monthly',
          description: 'With payments initiated over the past thirty days.',
        },
        firstDayOfTheMonth: {
          title: 'First day of the month',
          description: 'With payments initiated in the previous month.',
        },
      },
    },
    reportSendNow: {
      title: 'Enviar ahora',
      description:
        'El archivo que contiene los datos filtrados se enviará por correo electrónico a ',
      sendButton: 'Enviar',
      goBackButton: 'Volver',
      successSend: 'El informe se enviará por correo electrónico pronto.',
      errorSend: 'No se puede enviar el informe. Inténtalo de nuevo.',
      noSet: 'Sin configurar',
      createDate: 'Fecha de creación',
    },
  },
  countries: {
    BR: 'Brasil',
    MX: 'México',
    CO: 'Colombia',
    EC: 'Ecuador',
    AR: 'Argentina',
  },
  filter: {
    paymentStatus: 'Estado del pago',
    orderStatus: 'Estado del pedido',
    creationDate: 'Fecha de creación',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    endDateHint: 'Leave this field empty to search only by the start date.',
    clearFilters: 'Borrar todo',
    applyFilters: 'Aplicar',
    cancel: 'Cancelar',
    search: 'Search',
    all: 'All',
    paymentMethod: 'Payment method',
    description: 'El rango de fechas máximo es de 365 días.',
    filteredBy: 'Filtered by: ',
    messageError: {
      invalidDateRange: 'Invalid date range',
      dateRangeLimit: 'Date range cannot exceed 365 days',
      endDateError: 'End date cannot be earlier than start date',
      invalidDateError: 'Invalid date.',
    },
    labels: {
      paymentStatusExpired: 'Vencido',
      paymentStatusFailed: 'Falló',
      paymentStatusPaid: 'Pagado',
      orderStatusAvailable: 'Disponible',
      orderStatusCancelled: 'Cancelado',
      orderStatusConfirmed: 'Confirmado',
      orderStatusDelivered: 'Entregado',
      orderStatusDenied: 'Denegado',
      orderStatusExpired: 'Vencido',
      orderStatusInvoiced: 'Facturado',
      orderStatusInTransit: 'En tránsito',
      orderStatusModified: 'Modificado',
      orderStatusPaid: 'Pagado',
      orderStatusPartialDelivery: 'Entregado parcialmente',
      orderStatusPending: 'Pendiente',
      orderStatusPendingCancellation: 'Cancelación pendiente',
      orderStatusPendingPayment: 'Pago pendiente',
      orderStatusPlaced: 'Hecho',
    },
  },
  table: {
    headers: {
      paymentId: 'ID de pago',
      orderNumber: 'ID de pedido',
      orderValue: 'Valor del pedido',
      sellerId: 'ID del vendedor',
      invoiceDate: 'Fecha de la factura',
      accountId: 'Código de PDV',
      invoiceNumber: 'ID de factura',
      paymentValue: 'Valor de pago',
      paymentStatus: 'Estado del pago',
      orderStatus: 'Estado del pedido',
      invoiceValue: 'Valor de la factura',
      invoiceStatus: 'Estado de la factura',
      paymentDate: 'Fecha de pago',
      paymentMethod: 'Método',
      paymentMethodCode: 'Proveedor de servicios',
      beneficiaryName: 'Nombre del beneficiario',
      beneficiaryId: 'ID del beneficiario',
      paymentMethodFee: 'Tarifa del método de pago',
      commissionFee: 'Tasa de comisión',
    },
  },
  pagination: {
    of: 'of',
  },
  statusOrders: {
    canceled: 'Cancelado',
    delivered: 'Delivered',
    expired: 'Vencido',
    failed: 'Falló',
    error: 'Error',
    inProgress: 'In progress',
    orderPlaced: 'Order placed',
    outForDelivery: 'Out for delivery',
    paid: 'Pagado',
    partiallyDelivered: 'Partially delivered',
    deliveredInfo: 'deliveredInfo',
    expiredInfo: 'expiredInfo',
    paymentPending: 'Payment pending',
    inProgressInfo: 'inProgressInfo',
    orderPlacedInfo: 'orderPlacedInfo',
    outForDeliveryInfo: 'outForDeliveryInfo',
    paidInfo: 'paidInfo',
    partiallyDeliveredInfo: 'partiallyDeliveredInfo',
    paymentPendingInfo: 'paymentPendingInfo',
    errorInfo: 'errorInfo',
    canceledInfo: 'canceledInfo',
    processingInfo: 'processingInfo',
    failedInfo: 'failedInfo',
    waitingProviderInfo: 'waitingProviderInfo',
    waitingMerchantInfo: 'waitingMerchantInfo',
  },
  manageScheduledReports: {
    title: 'Administrar informes programados',
    sendButton: 'Sí, eliminar',
    back: 'No, volver',
    error: 'No se puede eliminar el informe programado. Inténtalo de nuevo.',
    success: 'Entrega de informe eliminada.',
    descriptionEmpty:
      'No hay informes programados. Puedes programarlos en la página de Transacciones.',
    reportName: 'Informe',
  },
  downloadDaysOfWeek: {
    MONDAY: 'Lunes',
    TUESDAY: 'Martes',
    WEDNESDAY: 'Miércoles',
    THURSDAY: 'Jueves',
    FRIDAY: 'Viernes',
  },
  reconciliation: {
    title: 'Reconciliation',
    description: 'Download files to perform reconciliations faster and more efficiently.',
    buttons: {
      downloadFile: 'Download file',
    },
  },
  workflow: {
    noAdapters: 'Nenhum adaptador disponível para este país.',
    newWorkflowDialog: {
      dialogTitle: 'Novo fluxo de trabalho',
      workflowTitleLabel: 'Título',
      workflowTitlePlaceholder: 'Insira o título',
      workflowTitleError: 'Título é obrigatório',
      paymentMethodError: 'Método de pagamento é obrigatório',
      confirmButton: 'Salvar',
      cancelButton: 'Cancelar',
    },
  },
  createWorkflowPage: {
    selectedPaymentMethod: 'Método de pagamento:',
    publishWorkflow: 'Publicar',
    publishWorkflowSuccess: 'Fluxo de trabalho publicado com sucesso!',
    publishWorkflowError: 'Falha ao publicar o fluxo de trabalho.',
    addProviderDialog: {
      title: 'Selecione um provedor',
      saveButton: 'Salvar',
      emptySelect: 'Por favor, selecione um provedor antes de adicionar.',
      providerAlreadyAdded: 'Provedor {providerName} já adicionado',
      errorMessage: 'Nenhum adaptador disponível. Selecione um país diferente.',
    },
    providerCard: {
      succeeded: 'Sucesso',
      declined: 'Recusado',
      error: 'Erro',
    },
    connectProvidersDialog: {
      createTitle: 'Criar adaptador',
      editTitle: 'Editar adaptador',
      tooltip: 'Duplicar',
      selectPlaceholder: 'Valor de destino',
      button: {
        primary: 'Salvar',
        secondary: 'Cancelar',
      },
      errorFetchingPayload: 'Erro ao buscar payload do provedor.',
      invalidInput: 'Entrada inválida.',
      noPayloadAvailable: 'Nenhum payload disponível.',
    },
    removeProviderDialog: {
      title: 'Remover provedor',
      content: 'Tem certeza de que deseja remover este provedor?',
      button: {
        primary: 'Remover',
        secondary: 'Cancelar',
      },
    },
    removeEdgeDialog: {
      title: 'Remover conexão',
      content: 'Tem certeza de que deseja remover esta conexão?',
      button: {
        primary: 'Remover',
        secondary: 'Cancelar',
      },
    },
    editOrRemoveDialog: {
      title: 'Editar ou remover?',
      content: 'Você gostaria de editar a conexão entre os dois provedores ou removê-la?',
      button: {
        primary: 'Editar',
        secondary: 'Remover',
      },
      removeEdgeDialog: {
        title: 'Remover conexão',
        content: 'Tem certeza de que deseja remover esta conexão?',
      },
    },
  },
  paymentMethod: {
    BANK: 'Banco',
    CASH: 'Dinheiro',
    CREDIT: 'Crédito',
    CREDIT_CARD: 'Tarjeta de Crédito',
    DEBIT_CARD: 'Tarjeta de débito',
    PIX: 'Pix',
    WALLET: 'Billetera',
  },
  homePage: {
    pageTitle: 'Bem-vindo ao Payments Hub',
  },
};

export default es;
