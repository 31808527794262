import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const DatePickerInputWrapper = styled('div', {});

export const InputWrapper = styled('div', {
  width: '62%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%',
  padding: '0 3.5rem 0 1.5rem',
});

export const Description = styled('div', {
  fontFamily: 'Work Sans',
  fontWeight: '400',
  fontSize: '0.875',
  lineHeight: '1.25',
  color: 'rgba(20, 20, 20, 0.56)',
  padding: '1rem 3.5rem 1rem 1.5rem',
});

export const MessageError = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontFamily: 'Work Sans',
  fontWeight: '400',
  fontSize: '0.875',
  lineHeight: '1.25',
  color: '$semanticErrorText',
  padding: '0 3.5rem 1rem 1.5rem',
});

export const DatePickerWrapper = styled('div', {
  position: 'absolute',
  zIndex: 10000,

  '& .datePicker-1 > .hexa-ui-today': {
    backgroundColor: 'red',
  },
});

export const Label = styled('div', {
  fontFamily: 'Work Sans',
  fontWeight: '600',
  fontSize: '0.875',
  lineHeight: '1.25',
  color: '$textPrimary',
  marginBottom: '0.5rem',
});
