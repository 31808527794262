import { Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ModalWrapper = styled('div', {
  '.c-ktUxKa-eYaSdF-shouldAdjustPadding-true': {
    padding: '1rem 2rem 0 2rem',
  },
  '.c-kMMXDz-fKTZlZ-variant-overlay': {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  '.c-hkjiru': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '.c-eMgPXw': {
    padding: '0.5rem 1.5rem 0px',
  },
});

export const Title = styled('h1', {
  fontFamily: 'var(--fonts-heading)',
  color: 'var(--colors-neutral100)',
});

export const Description = styled(Paragraph, {
  fontWeight: 'var(--fontWeights-normal)',
  lineHeight: 'var(--lineHeights-6)',
  fontFamily: 'var(--fonts-paragraph)',
  color: 'var(--colors-interfaceLabelSecondary) !important',
  marginTop: '0.7rem',
});

export const ContainerHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Box = styled('div', {
  display: 'flex',
  gap: '0.625rem',
});

export const footerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export const WrapperContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  marginTop: '2rem',

  '@media (max-width: 700px)': {
    flexDirection: 'column',
  },
});

export const WrapperBox = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '20px',
});

export const WrapperLeftBox = styled('div', {
  width: '50%',
  '@media (max-width: 700px)': {
    width: '100%',
  },
});

export const Label = styled(Heading, {
  marginBottom: '10px',
});

export const WrapperFilters = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--fontSizes-6)',
  overflow: 'auto',
  height: '430px',
  width: '100%',
  marginTop: 'var(--fontSizes-5)',
  scrollbarColor: '#DBDADA transparent',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar': {
    height: '18px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '$4',
  },
  '&::-webkit-scrollbar-button': {
    display: 'none',
  },
});

export const widthStyles = {
  width: '0.18rem',
  height: 'auto',
};

export const StyledForm = styled('form', {
  height: '100%',
});
