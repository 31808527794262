import { useAuthenticationService } from 'admin-portal-shared-services';
import axios from 'axios';
import acceptedCountries, { AcceptedCountry } from 'utils/acceptedCountries';

export function isExpired(exp) {
  return Date.now() >= exp * 1000;
}

function redirect() {
  window.location.href = '/';
  throw new Error('Access Denied');
}

export const ApiInstance = (baseURL, country) => {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
  });

  instance.interceptors.request.use(
    (config) => {
      const authentication = useAuthenticationService();
      const token = authentication.getAuthHeader();

      if (token) {
        const JwtExp = authentication.getJWTExpiration();
        if (isExpired(parseInt(JwtExp, 10))) {
          authentication.setAuthHeader('');
          redirect();
        }

        config.headers.Accept = 'application/json';
        config.headers.Authorization = config.headers?.Authorization || token;
        config.headers.requestTraceId = config.headers?.requestTraceId || 'requestTraceId-123';
        config.headers.country = acceptedCountries.includes(country as AcceptedCountry)
          ? country
          : config.headers?.country || (country as AcceptedCountry);
      } else {
        redirect();
      }

      return config;
    },
    (error) => {
      return Promise.reject(new Error(`Request interceptor error: ${error}`));
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 502 && error.response.data.details[0].includes('CONFLICT')) {
        return Promise.reject(new Error(error.response.data.details[0]));
      }

      return Promise.reject(new Error(`Response interceptor error: ${error}`));
    }
  );

  return instance;
};
