import { useCallback } from 'react';
import { Checkbox as CheckboxComponent } from '@hexa-ui/components';
import * as S from './Checkbox.styles';
import { ICheckboxProps } from 'types/checkbox';

export const Checkbox = ({ options, value, setValue }: ICheckboxProps) => {
  const onValueChange = useCallback(
    (checkedValue: string) => {
      const selectedOption = options.find((option) => option.value === checkedValue);
      if (!selectedOption) return;

      const isSelected = value.some((v) => v.value === checkedValue);
      setValue(
        isSelected ? value.filter((v) => v.value !== checkedValue) : [...value, selectedOption]
      );
    },
    [options, value, setValue]
  );

  return (
    <S.InputWrapper>
      {options?.map((option) => (
        <CheckboxComponent
          onCheckedChange={() => onValueChange(option.value)}
          key={option.idName}
          id={option.idName}
          label={option.label}
          value={option.value}
          checked={value.some((v) => v.value === option.value)}
        />
      ))}
    </S.InputWrapper>
  );
};
