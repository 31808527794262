import { useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useFilter } from 'contexts';
import countryEnum from 'enums/countryEnum';
import { useIntl } from 'react-intl';
import { GetSelectedFiltersParams } from 'types/getSelectedFiltersParams';
import { ISelectedFilterParams, ISelectedFilterStatus } from 'types/schedule';
import { formatToDateTime, getLocalizedDate } from 'utils/date';
import { translateArray } from 'utils/translateArray';

export const getSelectedFilters = ({ column, modalType }: GetSelectedFiltersParams): ISelectedFilterParams[] => {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();
  const {
    paymentStatusValues = [],
    orderStatusValues = [],
    columnsToShow = [],
    fromCreatedAtValue = '',
    toCreatedAtValue = '',
  } = useFilter();
  const [{ selectedCountry }] = useAppHeader();

  return (
    selectedFilters({
      formatMessage,
      preferredLanguage,
      paymentStatusValues,
      orderStatusValues,
      columnsToShow,
      selectedCountry,
      fromCreatedAtValue,
      toCreatedAtValue,
      column,
      modalType,
    }) || []
  );
};

const getCreationDate = (
  fromCreatedAtValue: string,
  toCreatedAtValue: string,
  preferredLanguage: string,
  formatMessage: (messageDescriptor: { id: string }) => string
) => {
  if (fromCreatedAtValue && toCreatedAtValue) {
    return `${getLocalizedDate(
      formatToDateTime(fromCreatedAtValue, preferredLanguage),
      preferredLanguage
    )} - ${getLocalizedDate(
      formatToDateTime(toCreatedAtValue, preferredLanguage),
      preferredLanguage
    )}`;
  }
  if (fromCreatedAtValue) {
    return getLocalizedDate(
      formatToDateTime(fromCreatedAtValue, preferredLanguage),
      preferredLanguage
    );
  }
  if (toCreatedAtValue) {
    return getLocalizedDate(
      formatToDateTime(toCreatedAtValue, preferredLanguage),
      preferredLanguage
    );
  }
  return formatMessage({ id: 'resolvedRequestsPage.reportSendNow.noSet' });
};

interface SelectedFiltersParams {
  formatMessage: (messageDescriptor: { id: string }) => string;
  preferredLanguage: string;
  paymentStatusValues: ISelectedFilterStatus[];
  orderStatusValues: ISelectedFilterStatus[];
  columnsToShow: { Header: string }[];
  selectedCountry: string;
  fromCreatedAtValue: string;
  toCreatedAtValue: string;
  column: 'left' | 'right';
  modalType: 'ModalSendNow' | 'ModalSchedule';
}

const selectedFilters = ({
  formatMessage,
  preferredLanguage,
  paymentStatusValues,
  orderStatusValues,
  columnsToShow,
  selectedCountry,
  fromCreatedAtValue,
  toCreatedAtValue,
  column,
  modalType,
}: SelectedFiltersParams): ISelectedFilterParams[] => {
  try {
    const orderStatus = orderStatusValues.map((status) => status.label) ?? [];
    const paymentStatus = paymentStatusValues.map((status) => status.label) ?? [];
    const creationDate = getCreationDate(fromCreatedAtValue, toCreatedAtValue, preferredLanguage, formatMessage);
    const translatedHeader = translateArray(columnsToShow, formatMessage);

    const countryFilter = {
      id: 1,
      title: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.country' }),
      description: formatMessage(countryEnum[selectedCountry]),
    };

    const rightColumnFilters = [
      {
        id: 2,
        title: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.orderStatus' }),
        description: orderStatus.length > 0 ? orderStatus.join(', ') : formatMessage({ id: 'resolvedRequestsPage.scheduleNew.all' }),
      },
      {
        id: 3,
        title: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.paymentStatus' }),
        description: paymentStatus.length > 0 ? paymentStatus.join(', ') : formatMessage({ id: 'resolvedRequestsPage.scheduleNew.all' }),
      },
      {
        id: 4,
        title: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.columns' }),
        description: translatedHeader.length > 0 ? translatedHeader.map((column) => column.Header).join(', ') : formatMessage({ id: 'resolvedRequestsPage.scheduleNew.all' }),
      },
    ];

    const leftColumnFilters = [
      {
        id: 2,
        title: formatMessage({ id: 'resolvedRequestsPage.reportSendNow.createDate' }),
        description: creationDate,
      },
    ];

    const leftFilters = modalType === 'ModalSendNow' ? [countryFilter, ...leftColumnFilters] : leftColumnFilters;
    const rightFilters = modalType === 'ModalSendNow' ? rightColumnFilters : [countryFilter, ...rightColumnFilters];

    return column === 'left' ? leftFilters : rightFilters;
  } catch (error) {
    console.error('Error fetching selected filters', error);
    return [];
  }
};
