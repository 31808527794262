import { gql } from 'graphql-request';

export const createScheduleDataMutation = gql`
  mutation CreateSchedule($input: CreateScheduleInputType!) {
    createSchedule(input: $input) {
      id
      emailAddress
      downloadHour
      fileType
      status
      userRequest
      country
      columnFilters
      description
      deleted
      creationDate
      updateDate
      searchFilters {
        buyerId
        invoiceNumber
        invoiceStatus
        orderNumber
        paymentDue
        paymentMethod
        paymentStatus
      }
    }
  }
`;
