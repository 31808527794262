import styled, { keyframes } from 'styled-components';
import { Paragraph } from '@hexa-ui/components';
import { Box, Modal } from '@material-ui/core';

export const ScheduledModalModalWrapper = styled.div`
  .c-ktUxKa-eYaSdF-shouldAdjustPadding-true {
    padding: 1rem 2rem 0 2rem;
  }
`;

export const ScheduledModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--fonts-heading);
`;

export const ScheduledModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScheduledTrashModalWrapper = styled.div`
  position: absolute;
  min-height: 152px;
  width: 391px;
  border-radius: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`;

export const ScheduleModalBox = styled(Box)`
  display: flex;
  border-radius: 20px;
  box-shadow: 24px;
  background-color: #fff;
`;

export const ScheduledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ScheduleModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ScheduleModalHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 15px;
`;

export const ScheduledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ScheduledColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 28px;
  background-color: #f2f2f2;
  height: 28px;
`;

export const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftColumnItem = styled.div`
  display: flex;
  margin-left: 55px;
  align-items: center;
`;

export const LeftColumnMidleItem = styled.div`
  display: flex;
  margin-left: 360px;
  width: 100px;
  align-items: center;
`;

export const LeftColumnRightItem = styled.div`
  display: flex;
  margin-left: 34px;
  align-items: center;
`;

export const RightColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 30px;
`;

export const RightColumnItem = styled.div`
  display: flex;
  margin-left: 31px;
  align-items: center;
`;

export const ScheduledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ScheduledItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 50px;
`;

export const SchChevronItem = styled.div`
  margin-left: 16px;
  cursor: pointer;
  :hover {
    color: #f2f209;
  }
`;

export const SchFrequencyItem = styled.div`
  margin-left: 18px;
  width: 410px;
`;

export const SchDeliveryTimeItem = styled.div`
  margin-left: 21px;
  width: 75px;
`;

export const SchEmailItem = styled.div`
  width: 382px;
  margin-left: 60px;
`;

export const SchFileItem = styled.div`
  width: 50px;
  margin-left: 67px;
`;

export const FilterSumaryContainer = styled.div`
  display: flex;
  justify-content: space-around;

  width: 100%;
  background-color: #f2f2f2;
`;

export const FilterSumaryModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const FilterSumaryOrderModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 12px;
`;

export const FilterSumaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ScheduledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-right: 45px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const DeleteButtonWrapper = styled.div`
  margin-right: 12px;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: #e4423f;
  cursor: pointer;
  border-radius: 48px;
`;

export const SchTrashIcon = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 28px;
  :hover {
    color: #f2f209;
  }
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const ActivityIndicator = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7986cb;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
  margin: auto;
`;

export const ScheduledApointmentsFound = styled(Paragraph)`
  text-align: center;
  padding: 25px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const WrapperLoading = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
