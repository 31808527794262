import { Countries, PaymentCurrency } from 'enums/payments';

type Locale = {
  [key in PaymentCurrency]: {
    code: string;
    country: string;
  };
};

export const locales: Locale = {
  [PaymentCurrency.CO]: {
    code: 'es-CO',
    country: Countries.CO,
  },
  [PaymentCurrency.DO]: {
    code: 'es-DO',
    country: Countries.DO,
  },
  [PaymentCurrency.EC]: {
    code: 'es-EC',
    country: Countries.EC,
  },
  [PaymentCurrency.MX]: {
    code: 'es-MX',
    country: Countries.MX,
  },
};
