import { Button, Modal, Paragraph } from '@hexa-ui/components';
import { AlertDialogTitle } from '@radix-ui/react-alert-dialog';
import * as S from './Scheduled.styles';

export function ScheduledTrashModal({
  handleCancelClick,
  handleDeleteItem,
  isOpen,
  emailScheduled,
}) {
  const handleDeleteClick = () => {
    handleDeleteItem();
    handleCancelClick();
  };

  return (
    <Modal.Root
      open={isOpen}
      destructive="critical"
      title={
        <S.ScheduledModalTitleWrapper>
          <AlertDialogTitle>Permanently delete report?</AlertDialogTitle>
        </S.ScheduledModalTitleWrapper>
      }
      actions={
        <S.WrapperButtons>
          <Modal.Cancel>
            <Button
              size="medium"
              variant="secondary"
              onClick={() => {
                handleCancelClick();
              }}
            >
              Cancel
            </Button>
          </Modal.Cancel>
          <Modal.Action>
            <Button
              size="medium"
              variant="destructive"
              onClick={() => {
                handleDeleteClick();
              }}
            >
              Yes, delete
            </Button>
          </Modal.Action>
        </S.WrapperButtons>
      }
    >
      <Paragraph css={{ margin: '$4 0' }}>
        <strong>Email:</strong> {emailScheduled}
      </Paragraph>
    </Modal.Root>
  );
}
