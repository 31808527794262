import { Chip, TextButton } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { useMemo } from 'react';

import { formatToDateTime, getLocalizedDate } from 'utils/date';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { IFilters, defaultInitialFilterValues } from 'contexts';
import * as S from './SelectedValues.styles';

interface SelectedValuesProps {
  onApplyFilters?: (filters: IFilters) => void;
  onClearFilters?: () => void;
  filterValues?: IFilters;
}

export const SelectedValues = ({
  onApplyFilters,
  onClearFilters,
  filterValues,
}: SelectedValuesProps) => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const { formatMessage } = useIntl();

  const values: Array<{ key: string; label: string; onDelete: () => void }> = useMemo(() => {
    const handlers = {
      paymentStatus: (values: IFilters['paymentStatus']) => {
        if (!values.length) return;
        const labels = values.map((status) => status.label).join(', ');
        return {
          label: `${formatMessage({ id: 'filter.paymentStatus' })}: ${labels}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              paymentStatus: defaultInitialFilterValues['paymentStatus'],
            }),
        };
      },
      orderStatus: (values: IFilters['orderStatus']) => {
        if (!values.length) return;
        const labels = values.map((orderStatus) => orderStatus.label).join(', ');
        return {
          label: `${formatMessage({ id: 'filter.orderStatus' })}: ${labels}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              orderStatus: defaultInitialFilterValues['orderStatus'],
            }),
        };
      },
      fromCreatedAt: (value: IFilters['fromCreatedAt']) => {
        if (!value) return;
        const dates = [
          formatToDateTime(value, preferredLanguage),
          filterValues.toCreatedAt && formatToDateTime(filterValues.toCreatedAt, preferredLanguage),
        ]
          .filter(Boolean)
          .map((date) => getLocalizedDate(date, preferredLanguage));

        return {
          label: `${formatMessage({ id: 'filter.createdOn' })}: ${dates.join(' - ')}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              fromCreatedAt: defaultInitialFilterValues['fromCreatedAt'],
              toCreatedAt: defaultInitialFilterValues['toCreatedAt'],
            }),
        };
      },
    };

    return Object.entries(filterValues).reduce((acc, [key, value]) => {
      const data = handlers[key as keyof IFilters]?.(value);
      if (data) acc.push({ ...handlers[key as keyof IFilters](value), key });
      return acc;
    }, []);
  }, [filterValues]);

  if (!values.length) return null;

  return (
    <S.ChipsContainer data-testid="selected-values" className="selected-values">
      <span>{formatMessage({ id: 'filter.filteredBy' })}</span>
      {values.map((item) => (
        <Chip.Root key={item.key}>
          <Chip.Label>{item.label}</Chip.Label>
          <Chip.DeleteIcon onDelete={item.onDelete} />
        </Chip.Root>
      ))}
      <TextButton iconPosition="leading" icon={X} onClick={onClearFilters}>
        {formatMessage({ id: 'filter.clearFilters' })}
      </TextButton>
    </S.ChipsContainer>
  );
};
