import { Button, Dialog } from '@hexa-ui/components';
import { ChevronDown, Filter as FilterIcon, X } from '@hexa-ui/icons';
import { useUserMetadata } from 'admin-portal-shared-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox } from 'components';
import { orderStatusOptions, paymentStatusOptions } from 'constants/filter';
import { useFilter } from 'contexts/FilterContext';
import { IFilterProps } from 'types/filterBar';
import { DateFilter } from './DateFilter';
import * as S from './Filter.styles';
import { FilterTab } from './FilterTab';
import { translateArray } from 'utils/translateArray';

/* istanbul ignore next */
const FilterIconHexa = () => <FilterIcon size="medium" />;

export const Filter = ({
  defaultPage,
  onApplyFilters,
  onClearFilters,
  filterValues,
}: IFilterProps) => {
  const { formatMessage } = useIntl();
  const [activeTab, setActiveTab] = useState(0);
  const {
    paymentStatusValues,
    setPaymentStatusValues,
    orderStatusValues,
    setOrderStatusValues,
    fromCreatedAtValue,
    toCreatedAtValue,
    initializeFilters,
    isResetDisabled,
    isApplyDisabled,
    resetFilters,
  } = useFilter();
  const { data } = useUserMetadata();

  // const serviceStorage = JSON.parse(localStorage.getItem('sspa_layout'));
  // const mainService = serviceStorage.find((item) => item.name === '@admin-portal/main-mfe');
  // const appConfiguration = mainService?.props?.appsConfiguration;

  const handleOpen = (event: React.MouseEvent) => {
    setActiveTab(0);
    initializeFilters(filterValues);
  };

  const handleApply = useCallback(() => {
    onApplyFilters({
      paymentStatus: paymentStatusValues,
      orderStatus: orderStatusValues,
      fromCreatedAt: fromCreatedAtValue,
      toCreatedAt: toCreatedAtValue,
    });
  }, [paymentStatusValues, orderStatusValues, fromCreatedAtValue, toCreatedAtValue]);

  const handleReset = useCallback(() => {
    resetFilters();
    onClearFilters();
  }, []);

  useEffect(() => {
    handleReset();
  }, [defaultPage]);

  const translatedPaymentStatusOptions = useMemo(
    () => translateArray(paymentStatusOptions, formatMessage),
    [formatMessage]
  );

  const translatedOrderStatusOptions = useMemo(
    () => translateArray(orderStatusOptions, formatMessage),
    [formatMessage]
  );

  const tabs = useMemo(() => {
    const filterTabs = [
      {
        id: 'createdOn',
        name: formatMessage({ id: 'filter.creationDate' }),
        content: <DateFilter key="creationDate" />,
      },
      {
        id: 'orderStatus',
        name: formatMessage({ id: 'filter.orderStatus' }),
        content: (
          <Checkbox
            options={translatedOrderStatusOptions}
            value={orderStatusValues}
            setValue={setOrderStatusValues}
          />
        ),
      },
      {
        id: 'paymentStatus',
        name: formatMessage({ id: 'filter.paymentStatus' }),
        content: (
          <Checkbox
            options={translatedPaymentStatusOptions}
            value={paymentStatusValues}
            setValue={setPaymentStatusValues}
          />
        ),
      },
    ];

    return filterTabs;
  }, [
    data,
    paymentStatusValues,
    orderStatusValues,
    fromCreatedAtValue,
    toCreatedAtValue,
    defaultPage,
  ]);

  return (
    <div className="actions-container">
      <Dialog.Root
        variant="overlay"
        closeButton={false}
        contentCss={S.contentCss}
        trigger={
          <S.ButtonFilter
            icon={FilterIconHexa}
            size="medium"
            leading
            variant="secondary"
            data-testid="button-filter"
            onClick={handleOpen}
          >
            {formatMessage({ id: 'resolvedRequestsPage.commom.filter' })}
            <ChevronDown size="medium" />
          </S.ButtonFilter>
        }
        actions={
          <S.ActionsContainer>
            <Dialog.Close>
              <S.ClearFilterTextButton
                iconPosition="leading"
                icon={X}
                onClick={handleReset}
                disabled={isResetDisabled}
                className={`button-clear-all ${isResetDisabled ? 'disabled' : ''}`}
              >
                {formatMessage({ id: 'filter.clearFilters' })}
              </S.ClearFilterTextButton>
            </Dialog.Close>
            <div style={{ display: 'flex' }}>
              <Dialog.Close>
                <Button variant="secondary">{formatMessage({ id: 'filter.cancel' })}</Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  onClick={handleApply}
                  css={{ marginLeft: '$4' }}
                  disabled={isApplyDisabled(filterValues)}
                >
                  {formatMessage({ id: 'filter.applyFilters' })}
                </Button>
              </Dialog.Close>
            </div>
          </S.ActionsContainer>
        }
      >
        <S.ContentContainer>
          <S.TabSelection>
            {tabs.map((tab, index) => {
              return FilterTab({ tab, index, activeTab, setStateFunction: setActiveTab });
            })}
          </S.TabSelection>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <S.TabContent>{tabs[activeTab].content}</S.TabContent>
          </div>
        </S.ContentContainer>
      </Dialog.Root>
    </div>
  );
};
