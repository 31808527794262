import jwtDecode from 'jwt-decode';
import { DecodeToken } from 'types/payments';

const decodeToken = (localStorageKey = 'authHeader') => {
  try {
    const token = localStorage.getItem(localStorageKey);

    if (!token) {
      throw new Error('Token not found');
    }

    const data: DecodeToken = jwtDecode(token);

    return data;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

export default decodeToken;
