import { Checkbox, Heading, Paragraph, Radio } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { TextField } from '@material-ui/core';
import styledComponent from 'styled-components';

export const FilterStyledForm = styled('form', {
  width: '100%',
  height: '100%',
});

export const FilterContainer = styled('div', {
  display: 'Flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0px!important',
  margin: '0px!important',
});

export const FilterGridTable = styled('div', {
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0!important',
});

export const FilterGridResults = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '1.5em',
});

export const FilterGridHeading = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DCDCDC',
});

export const FilterGridFilter = styled('div', {
  width: '25%',
  height: 'auto',
  padding: '0',
  borderRight: '1px solid #DCDCDC',
});

export const FilterHeadingTitle = styled(Heading, {
  width: '25%',
  borderRight: '1px solid #DCDCDC',
  padding: '1.7rem 1rem',
});

export const FilterInputWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
  '& div > div > div > div': {
    paddingBottom: '0.4rem',
  },
  '& div': {
    boxShadow: 'none',
  },

  '& div > div > header': {
    width: '100%',

    '@media (max-width: 1457px)': {
      width: '70%',
    },

    '@media (max-width: 1067px)': {
      width: '60%',
    },
    '@media (max-width: 831px)': {
      width: '50%',
    },
    '@media (max-width: 700px)': {
      width: '45%',
    },
  },

  '& div > div': {
    background: 'transparent',
  },
});

export const FilterInputSearchWrapper = styled('div', {
  margin: '1rem',
});

export const FilterInputWrapperList = styled('div', {
  marginTop: '10px',
  '& > div': { paddingBottom: '5px' },

  '.MuiCheckbox-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  '.MuiCheckbox-colorSecondary.Mui-checked': {
    color: '#000',

    '&:hover': {
      backgroundColor: 'red',
    },
  },
});

export const FilterInputWrapperNoBorder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
  flexWrap: 'wrap',

  '& div > div > div > div': {
    paddingBottom: '0.4rem',
  },
  '& div': {
    boxShadow: 'none',
  },
});

export const FilterInputDate = styledComponent(TextField)``;

export const FilterDateWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  gap: '1rem',
  margin: '0',
  padding: '0',

  '& div > div': {
    borderRadius: '30px!important',
  },
});

export const FilterSeparator = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const FilterTitle = styled(Heading, {
  marginBottom: '0.5rem',
  fontSize: '1rem',
});

export const FilterWrapperButton = styled('div', {
  width: '100%',
  padding: '1.7rem 1.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  gap: '1rem',
  borderTop: '1px solid #DCDCDC',
});

export const FilterLoading = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FilterCheckbox = styled(Checkbox, {
  margin: '3px 0px',
});

export const FilterRadio = styled(Radio.Item, {});
