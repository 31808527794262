export const managerTypeUser = [
  {
    type: 'VIEWER_3P',
    key: [
      'PAYMENT_METHOD',
      'PAYMENT_METHOD_CODE', // = SERVICE PROVIDER
      'ORDER_STATUS',
      'ORDER_VALUE',
      'ORDER_NUMBER', // = ORDER ID
      'BENEFICIARY_ID',
      'BENEFICIARY_NAME',
      'PAYMENT_METHOD_FEE',
      'COMMISSION_FEE',
      'PAYMENT_ID',
      'PAYMENT_DATE',
      'PAYMENT_STATUS',
      'PAYMENT_VALUE',
      'ACCOUNT_ID', // = POC ID
      'SELLER_ID',
      'INVOICE_VALUE',
      'INVOICE_STATUS',
      'INVOICE_DATE',
      'INVOICE_NUMBER', // = INVOICE ID
    ],
  },
  {
    type: 'VIEWER_GLOBAL',
    key: [
      'PAYMENT_ID',
      'SELLER_ID',
      'ACCOUNT_ID', // = POC ID
      'PAYMENT_VALUE',
      'PAYMENT_STATUS',
      'PAYMENT_METHOD',
      'PAYMENT_METHOD_CODE', // = SERVICE PROVIDER
      'PAYMENT_DATE',
    ],
  },
  {
    type: 'VIEWER_ADM',
    key: [
      'PAYMENT_ID',
      'ORDER_NUMBER', // = ORDER ID
      'SELLER_ID',
      'ACCOUNT_ID', // = POC ID
      'INVOICE_NUMBER', // = INVOICE ID
      'PAYMENT_VALUE',
      'PAYMENT_STATUS',
      'PAYMENT_METHOD',
      'PAYMENT_METHOD_CODE', // = SERVICE PROVIDER
      'ORDER_STATUS',
      'ORDER_VALUE',
      'INVOICE_VALUE',
      'INVOICE_DATE',
      'INVOICE_STATUS',
      'PAYMENT_DATE',
      'BENEFICIARY_ID',
      'BENEFICIARY_NAME',
      'PAYMENT_METHOD_FEE',
      'COMMISSION_FEE',
    ],
  },
];
