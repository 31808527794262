import { defineMessages } from 'react-intl';

const countryEnum = defineMessages({
  BR: { id: 'countries.BR', defaultMessage: 'Brasil' },
  MX: { id: 'countries.MX', defaultMessage: 'México' },
  AR: { id: 'countries.AR', defaultMessage: 'Argentina' },
  CO: { id: 'countries.CO', defaultMessage: 'Colômbia' },
  PE: { id: 'countries.PE', defaultMessage: 'Peru' },
  CL: { id: 'countries.CL', defaultMessage: 'Chile' },
  EC: { id: 'countries.EC', defaultMessage: 'Equador' },
});

export default countryEnum;
