import { ModalContext } from 'contexts';
import { OptionProps } from 'pages/Reports/components/ScheduleModal/Schedule.types';
import {
  daysOfWeekValues,
  fileFormatValues,
} from 'pages/Reports/components/ScheduleModal/utils/data';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import scheduleToken from '../utils/scheduleToken';

export function useScheduleModal() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = useForm();

  const { scheduleModal } = useContext(ModalContext);

  const [selectedDays, setSelectedDays] = useState<OptionProps[]>(daysOfWeekValues);
  const [fileFormat, setFileFormat] = useState<OptionProps[]>(fileFormatValues);
  const [downloadHour, setDownloadHour] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(true);
  const emailWatch = watch('email');

  useEffect(() => {
    setHasError(
      !Boolean(selectedDays?.filter((data) => data.value).length && emailWatch && downloadHour)
    );
  }, [emailWatch, selectedDays, downloadHour]);

  const handleSelectDay = (name: string) => {
    const newSelectedDays = selectedDays.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          value: !item.value,
        };
      }
      return item;
    });
    setSelectedDays(newSelectedDays);
  };

  const handleSelectFileFormat = (name: string) => {
    const newFileFormat = fileFormat.map((item) => {
      return {
        ...item,
        value: item.name === name,
      };
    });
    setFileFormat(newFileFormat);
  };

  const onSubmit = () => {
    try {
      const downloadDaysOfWeek = selectedDays.filter((item) => item.value).map((item) => item.key);
      const downloadFileFormat = fileFormat.filter((item) => item.value).map((item) => item.key);

      const userScheduleToken = scheduleToken();
      const email = userScheduleToken?.email;

      scheduleModal.handleCreateSchedule({
        emailAddress: email,
        downloadDaysOfWeek,
        downloadHour,
        fileType: downloadFileFormat[0],
      });
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    isValid,
    isDirty,
    hasError,
    handleSelectDay,
    handleSelectFileFormat,
    setDownloadHour,
    downloadHour,
    selectedDays,
    fileFormat,
  };
}
