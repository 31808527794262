import { HandleClickOutsideParams } from 'types/dropdownButton';

export const handleClickOutside = ({
  event,
  buttonRef,
  submenuRef,
  setChangeIcon,
}: HandleClickOutsideParams) => {
  try {
    if (
      event.target instanceof Node &&
      !buttonRef.current?.contains(event.target) &&
      !submenuRef.current?.contains(event.target)
    ) {
      setChangeIcon(false);
    }
  } catch (error) {
    console.error('Error handling click outside:', error);
    setChangeIcon(false);
  }
};
