import { Button, DatePicker, Heading, LoadingBuzz, SearchField } from '@hexa-ui/components';
import { TableContent } from 'components';
import { combinedLabels } from 'constants/filterLabelsInput';
import { IdGroupCheckBox, OrderStatusLabels } from 'enums/payments';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { CardWrapper } from '..';
import { FilterOrder, FilterResults } from './components';
import * as S from './Filter.styles';
import { formatDate, formatValue } from './utils/formatters';

import { managerTypeUser } from 'constants/managerUserViewerType';
import { FilterLabels, UserViewerEnum } from 'enums/filter';
import { ColumnData } from 'pages/Reports/types/Reports.types';
import { reportSelectedUserViewer } from 'store/selectors/report.selectors';
import { setFieldsToExport } from 'store/slices/reports.slice';
import { IdType } from 'types/payments';
import { FilterValues } from 'types/tableRequest';
import { FormData, ICreateNewObjectItem, IFormatProperty } from './Filter.types';

export function Filter({
  data,
  columns,
  setPayload,
  isLoading,
  isRefetching,
  totalRecords,
  isError,
  pagination = {},
}) {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm<FormData>();
  const [orderStatus, setOrderStatus] = useState<string[]>([]);
  const [rangeDate, setRangeDate] = useState<Date[]>();
  const [invoiceStatus, setInvoiceStatus] = useState<string[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<string[]>([]);
  const [columnsToShow, setColumnsToShow] = useState<ColumnData[]>(columns);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchLabel, setSearchLabel] = useState<string>('accountId');
  const typeUser = useSelector(reportSelectedUserViewer);

  const filterOrder = useMemo(() => {
    const order = [
      FilterLabels.INVOICE_STATUS,
      FilterLabels.ORDER_STATUS,
      FilterLabels.PAYMENT_STATUS,
    ];
    const hideCheckboxesFilter = [UserViewerEnum.VIEWER_3P, UserViewerEnum.VIEWER_GLOBAL];

    return order.filter((status) => {
      if (
        hideCheckboxesFilter.some((viewer) => typeUser.includes(viewer)) &&
        status === FilterLabels.INVOICE_STATUS
      ) {
        return false;
      }
      return true;
    });
  }, [typeUser]);

  const orderedCombinedLabels = useMemo(() => {
    return filterOrder
      .map((status) => {
        return combinedLabels.find((labelGroup) => labelGroup.title === status);
      })
      .filter(Boolean);
  }, [filterOrder, combinedLabels]);

  const allowedColumns = Array.isArray(managerTypeUser)
    ? managerTypeUser.find((userType) => typeUser.includes(userType.type))?.key || []
    : [];

  useEffect(() => {
    const filteredColumns = columns.filter((column: ColumnData) =>
      allowedColumns.includes(column.key)
    );
    setColumnsToShow(filteredColumns);
  }, [typeUser]);

  useEffect(() => {
    dispatch(setFieldsToExport(columnsToShow.map((item) => item.key)));
  }, [columnsToShow]);

  const onSubmit: SubmitHandler<FormData> = useCallback(() => {
    let payload: FilterValues = {};

    const states = {
      rangeDate: rangeDate,
      orderStatus: orderStatus,
      invoiceStatus: invoiceStatus,
      paymentStatus: paymentStatus,
    };

    const stateToPayloadKey = {
      rangeDate: {
        format: (dates: Date[]) => ({
          orderDateFrom: dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : null,
          orderDateTo: moment(dates[1] || dates[0]).format('YYYY-MM-DD'),
        }),
      },
      orderStatus: {
        format: (status: string[]) => ({ orderStatus: status }),
      },
      invoiceStatus: {
        format: (status: string[]) => ({ invoiceStatus: status }),
      },
      paymentStatus: {
        format: (status: string[]) => ({ paymentStatus: status }),
      },
    };

    Object.entries(stateToPayloadKey).forEach(([stateKey, { format }]) => {
      const stateValue = states[stateKey];
      if (stateValue?.length) {
        Object.assign(payload, format(stateValue));
      }
    });

    if (invoiceStatus?.length) {
      payload = {
        ...payload,
        invoiceStatus,
      };
    }

    if (paymentStatus?.length) {
      payload = {
        ...payload,
        paymentStatus,
      };
    }

    if (searchInput && searchLabel) {
      payload = {
        ...payload,
        [searchLabel]: searchInput,
      };
    }
    setPayload(payload);
  }, [rangeDate, orderStatus, invoiceStatus, paymentStatus, setPayload, searchInput, searchLabel]);

  const handleColumns = (activeColumns: string[]) => {
    const filter: ColumnData[] = columns.filter(
      (column: ColumnData) =>
        activeColumns.includes(column.key) && allowedColumns.includes(column.key)
    );
    setColumnsToShow(filter);
  };

  const resetAllForm = () => {
    // TODO:
    // Remove this function after updating the project to React 18
    // since it supports batched updates automatically.
    // https://dev.to/devmoustafa97/do-you-know-unstablebatchedupdates-in-react-enforce-batching-state-update-5cn2
    unstable_batchedUpdates(() => {
      setRangeDate(null);
      setOrderStatus([]);
      setInvoiceStatus([]);
      setPaymentStatus([]);
      setPayload({});
      onClearSearch();
    });
  };

  const formatProperty = {
    paymentCaptureDate: formatDate,
    orderDate: formatDate,
    paymentDate: formatDate,
    invoiceDate: formatDate,
    invoiceValue: formatValue,
    orderStatus: (status: string) => OrderStatusLabels[status] || status,
  };

  const createNewObject = (item: ICreateNewObjectItem, formatProperty: IFormatProperty) => {
    let newObj = {};

    for (let prop in item) {
      if (formatProperty[prop]) {
        newObj[prop] = formatProperty[prop](item[prop]);
      } else {
        newObj[prop] = item[prop] || '-';
      }
    }
    return newObj;
  };

  const addAdditionalProperties = (
    item: ICreateNewObjectItem,
    newObj,
    formatProperty: IFormatProperty
  ) => {
    newObj = {
      ...newObj,
      barCodeGenerated: item.barCodeGenerated || '-',
      barCodeStatus: item.barCodeStatus || '-',
      //TODO: item.paymentDate formated
      paymentDate: item.paymentDate ? formatProperty.paymentDate(item.paymentDate) : '-',
      paymentId: item.paymentId || '-',
      paymentStatus: item.paymentStatus || '-',
      sellerId: item.sellerId || '-',
      storeName: item.storeName || '-',
      invoiceNumber: item.invoiceNumber || '-',
      paymentValue: item.paymentValue ? item.paymentValue : '-',
      orderStatus: item.orderStatus || '-',
      invoiceValue: item.invoiceValue || '-',
      invoiceStatus: item.invoiceStatus || '-',
      orderNumber: item.orderNumber || '-',
      orderValue: item.orderValue || '-',
      orderDate: item.orderDate || '-',
      email: item.email || '-',
      buyerId: item.buyerId || '-',
      paymentMethodFee: item.paymentMethodFee || '-',
      commissionFee: item.commissionFee || '-',
      beneficiary: item?.beneficiaryId || '-',
      beneficiaryName: item?.beneficiaryName || '-',
    };

    return newObj;
  };

  const newData = data.map((item: ICreateNewObjectItem) => {
    const newObj = createNewObject({ ...item }, formatProperty);
    return addAdditionalProperties(item, newObj, formatProperty);
  });

  const handleSearchFieldChange = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const onClearSearch = useCallback(() => {
    setSearchInput('');
    setSearchLabel('accountId');
  }, []);

  const handleIDCheckBoxChange = (value: string, idType: IdType) => {
    let currentStatus: string[];
    let setStatus: React.Dispatch<React.SetStateAction<string[]>>;

    switch (idType) {
      case IdGroupCheckBox.order:
        currentStatus = orderStatus;
        setStatus = setOrderStatus;
        break;
      case IdGroupCheckBox.invoice:
        currentStatus = invoiceStatus;
        setStatus = setInvoiceStatus;
        break;
      case IdGroupCheckBox.payment:
        currentStatus = paymentStatus;
        setStatus = setPaymentStatus;
        break;
      default:
        throw new Error(`Unknown status type: ${idType}`);
    }

    const newValue = currentStatus.includes(value)
      ? currentStatus.filter((status) => status !== value)
      : [...currentStatus, value];

    setStatus(newValue);
  };

  return (
    <CardWrapper border="large" elevated="xlarge">
      <S.FilterGridHeading>
        <S.FilterHeadingTitle size="H3">Filter by</S.FilterHeadingTitle>
        <S.FilterGridResults>
          <FilterResults
            offset={data.length}
            total={totalRecords}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </S.FilterGridResults>
      </S.FilterGridHeading>
      <S.FilterContainer>
        <S.FilterGridFilter>
          <S.FilterStyledForm onSubmit={handleSubmit(onSubmit)}>
            <S.FilterInputSearchWrapper>
              <S.FilterTitle size="H5" alignment="left">
                Search
              </S.FilterTitle>
              <SearchField.Root
                placeholder="Search"
                width="100%"
                size="large"
                data-testid="search"
                value={searchInput}
                onChange={(e) => handleSearchFieldChange((e.target as HTMLInputElement).value)}
                onClear={onClearSearch}
              >
                <SearchField.Filter
                  placeholder="Filter"
                  value={searchLabel}
                  onChange={setSearchLabel}
                >
                  <SearchField.FilterOption value="accountId">POC ID</SearchField.FilterOption>
                  <SearchField.FilterOption value="paymentId">Payment ID</SearchField.FilterOption>
                </SearchField.Filter>
              </SearchField.Root>
            </S.FilterInputSearchWrapper>
            <S.FilterInputWrapperNoBorder>
              <S.FilterTitle size="H5" alignment="left">
                Order date
              </S.FilterTitle>
              <S.FilterDateWrapper>
                <DatePicker
                  value={rangeDate}
                  onChange={setRangeDate}
                  onSave={setRangeDate}
                  numberOfMonths={2}
                  mode="range"
                  width="100%"
                  callToActionSection
                  customLocale="en"
                />
              </S.FilterDateWrapper>
            </S.FilterInputWrapperNoBorder>
            {orderedCombinedLabels.map((labelGroup) => (
              <S.FilterInputWrapper key={labelGroup.title}>
                <Heading size="H5" alignment="left">
                  {labelGroup.title}
                </Heading>
                <S.FilterInputWrapperList>
                  {labelGroup.options.map((option) => {
                    const idMapType = {
                      [FilterLabels.PAYMENT_STATUS]: IdGroupCheckBox.payment,
                      [FilterLabels.ORDER_STATUS]: IdGroupCheckBox.order,
                      [FilterLabels.INVOICE_STATUS]: IdGroupCheckBox.invoice,
                    };

                    const checkBoxID = idMapType[labelGroup.title];

                    if (!checkBoxID) {
                      throw new Error(`Unknown status type for group title: ${labelGroup.title}`);
                    }

                    const isChecked = {
                      order: orderStatus,
                      invoice: invoiceStatus,
                      payment: paymentStatus,
                    }[checkBoxID].includes(option.value);

                    return (
                      <S.FilterCheckbox
                        key={option.idName}
                        label={option.label}
                        checked={isChecked}
                        onClick={() => handleIDCheckBoxChange(option.value, checkBoxID)}
                        id={option.idName}
                      />
                    );
                  })}
                </S.FilterInputWrapperList>
              </S.FilterInputWrapper>
            ))}
            <S.FilterWrapperButton>
              <Button size="large" type="button" onClick={() => resetAllForm()} variant="secondary">
                Reset filters
              </Button>
              <Button size="large" type="submit" data-testid="save-button" variant="primary">
                Apply filters
              </Button>
            </S.FilterWrapperButton>
          </S.FilterStyledForm>
        </S.FilterGridFilter>
        <S.FilterGridTable>
          <FilterOrder columns={columnsToShow} onChange={handleColumns} />
          {isLoading || isRefetching ? (
            <S.FilterLoading>
              <LoadingBuzz size="medium" />
            </S.FilterLoading>
          ) : (
            <TableContent
              dataTable={newData}
              columns={columnsToShow}
              pagination={pagination}
              isError={isError}
            />
          )}
        </S.FilterGridTable>
      </S.FilterContainer>
    </CardWrapper>
  );
}
