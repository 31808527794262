import { Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import * as S from './Header.styles';
import { IHeaderProps } from 'types/header';

export const Header = ({ title, description, children }: IHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <S.WrapperHeader className="wrapper-header">
      <S.WrapperAlignTitle className="wrapper-align-title">
        <Heading headers="h2">{formatMessage({ id: title })}</Heading>
        {description && <S.Description>{formatMessage({ id: description })}</S.Description>}
      </S.WrapperAlignTitle>

      <S.WrapperAlignButtons className="wrapper-align-buttons">{children}</S.WrapperAlignButtons>
    </S.WrapperHeader>
  );
};
