// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mfe-content > header > div > div > div > h1 {
  display: none;
}

#mfe-content-header > div > div > div > div > div {
  margin-bottom: 10px;
}

.wrapper-align-buttons {
  margin-top: 3px;
}

#mfe-content > header > div > div > div {
  row-gap: 0;
}

#mfe-content > header > div > div {
  padding: 24px 0px 0px 0px;
}

/*DataPiker*/
.datePicker-1 > div > div > div > div > div > div > div,
.datePicker-2 > div > div > div > div > div > div > div {
  display: none;
  margin-bottom: 0 !important;
}

.datePicker-1 > div > div > div > div > div > div > div > div > div,
.datePicker-2 > div > div > div > div > div > div > div > div > div {
  margin-top: -30px;
}

.hexa-ui-today {
  background-color: #fff !important;
  border: 1px solid rgba(20, 20, 20, 0.12) !important;
  border-radius: 50% !important;
  color: rgba(20, 20, 20, 1) !important;
}

.hexa-ui-selected {
  border: 1px solid rgba(20, 20, 20, 0.12) !important;
  border-radius: 50% !important;
}

/* workflow team */
#mfe-content-header > div > div > div > div > button {
  margin-top: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/App.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,YAAY;AACZ;;EAEE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,mDAAmD;EACnD,6BAA6B;EAC7B,qCAAqC;AACvC;;AAEA;EACE,mDAAmD;EACnD,6BAA6B;AAC/B;;AAEA,kBAAkB;AAClB;EACE,gBAAgB;AAClB","sourcesContent":["#mfe-content > header > div > div > div > h1 {\n  display: none;\n}\n\n#mfe-content-header > div > div > div > div > div {\n  margin-bottom: 10px;\n}\n\n.wrapper-align-buttons {\n  margin-top: 3px;\n}\n\n#mfe-content > header > div > div > div {\n  row-gap: 0;\n}\n\n#mfe-content > header > div > div {\n  padding: 24px 0px 0px 0px;\n}\n\n/*DataPiker*/\n.datePicker-1 > div > div > div > div > div > div > div,\n.datePicker-2 > div > div > div > div > div > div > div {\n  display: none;\n  margin-bottom: 0 !important;\n}\n\n.datePicker-1 > div > div > div > div > div > div > div > div > div,\n.datePicker-2 > div > div > div > div > div > div > div > div > div {\n  margin-top: -30px;\n}\n\n.hexa-ui-today {\n  background-color: #fff !important;\n  border: 1px solid rgba(20, 20, 20, 0.12) !important;\n  border-radius: 50% !important;\n  color: rgba(20, 20, 20, 1) !important;\n}\n\n.hexa-ui-selected {\n  border: 1px solid rgba(20, 20, 20, 0.12) !important;\n  border-radius: 50% !important;\n}\n\n/* workflow team */\n#mfe-content-header > div > div > div > div > button {\n  margin-top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
