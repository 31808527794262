import { Edge, Node, useEdgesState, useNodesState } from '@xyflow/react';
import { useAppHeader } from 'admin-portal-shared-services';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAdapters } from 'services/workflow/getAdapters';
import { WORKFLOW_PATH } from 'src/routes';
import { IAdapter, IAdaptersContext, IDialogConfig } from 'types/adapter';
import { PaymentMethodsType } from 'types/workflow';
import { useEnvContext } from 'utils/EnvProvider';

const AdaptersContext = createContext<IAdaptersContext | undefined>(undefined);

export const AdaptersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [{ selectedVendor, vendorOptions, selectedCountry }] = useAppHeader();
  const { REACT_APP_CONFIGURATOR_API } = useEnvContext();
  const navigate = useNavigate();

  const [adapters, setAdapters] = useState<IAdapter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [userCountry, setUserCountry] = useState<string>();
  const [selectedProvider, setSelectedProvider] = useState<IAdapter | null>(null);
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodsType | null>(
    null
  );
  const [selectedVendorName, setSelectedVendorName] = useState<string>('');
  const [newWorkflowTitleError, setNewWorkflowTitleError] = useState<boolean>(false);
  const [newWorkflowTitle, setNewWorkflowTitle] = useState<string>('');
  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState<boolean>(false);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [addProviderDialogOpen, setAddProviderDialogOpen] = useState<boolean>(false);
  const [selectedAdapterId, setSelectedAdapterId] = useState<string>('');
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [payloadFieldValues, setPayloadFieldValues] = useState<Record<string, string>>({});
  const [accessedViaHeader, setAccessedViaHeader] = useState<boolean>(false);

  const selectedPaymentMethodRef = useRef<PaymentMethodsType | null>(null);
  const initialProvider = useRef<IAdapter | null>(null);
  const targetProviderId = useRef<string | null>(null);
  const formValuesRef = useRef<Record<string, unknown>>({});
  const nodesRef = useRef<Node[]>([]);
  const edgesRef = useRef<Edge[]>([]);
  const nodeIdRef = useRef<string | null>(null);
  const clickedHandleIdRef = useRef<string | null>(null);
  const dialogConfigRef = useRef<IDialogConfig>({
    type: null,
    open: false,
    nodeId: null,
    edgeParams: null,
    selectedProviderId: null,
  });

  const [dialogConfigState, setDialogConfigState] = useState<IDialogConfig>({
    type: null,
    open: false,
    nodeId: null,
    edgeParams: null,
    selectedProviderId: null,
  });

  useEffect(() => {
    const segments = location.pathname.split('/').filter(Boolean);
    setPathSegments(segments);
  }, [location]);

  useEffect(() => {
    const currentVendor = vendorOptions?.filter((item) => item.id === selectedVendor);
    currentVendor.map((item) => {
      setSelectedVendorName(item.displayName);
    });
  }, [vendorOptions, selectedVendor]);

  useEffect(() => {
    if (selectedCountry) {
      setUserCountry(selectedCountry);
      // setUserCountry('PE'); // Uncomment this line to test on local env.
    }
  }, [selectedCountry]);

  useEffect(() => {
    const fetchAdapters = async () => {
      if (!userCountry) {
        setError(new Error('Country is not specified'));
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const result = await getAdapters(userCountry);
        const filteredAdapters = result
          .filter(
            (adapter) =>
              adapter.countries.includes(userCountry) || adapter.countries.includes('GLOBAL')
          )
          .map((adapter, i) => ({
            ...adapter,
            position: { x: 0, y: 20 + i },
          }));

        setAdapters(filteredAdapters);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdapters();
  }, [userCountry, REACT_APP_CONFIGURATOR_API]);

  const clearWorkflowCreation = useCallback(() => {
    if (nodes || edges) {
      setNodes([]);
      setEdges([]);
      setFormValues({});
      formValuesRef.current = {};
      setSelectedPaymentMethod(null);
      selectedPaymentMethodRef.current = null;
      setNewWorkflowTitle('');
      setSelectedPaymentMethodError(false);
      setNewWorkflowTitleError(false);

      location.pathname !== WORKFLOW_PATH && navigate(WORKFLOW_PATH);
    }
  }, [
    navigate,
    setNodes,
    setEdges,
    setSelectedPaymentMethod,
    selectedPaymentMethodRef,
    setNewWorkflowTitle,
    setSelectedPaymentMethodError,
    setNewWorkflowTitleError,
  ]);

  const getProviderPayload = useCallback(
    (providerId: string) => {
      const provider = adapters.find((adapter) => adapter.id === providerId);
      if (provider) {
        return Object.fromEntries(
          Object.entries(provider.payload).map(([key, value]) => [
            key,
            typeof value === 'string' && ['string', 'number', 'boolean'].includes(value)
              ? ''
              : value,
          ])
        );
      }
      return {};
    },
    [adapters]
  );

  const setFormValues = useCallback(
    (newValues: Record<string, any> | ((prev: Record<string, any>) => Record<string, any>)) => {
      if (typeof newValues === 'function') {
        formValuesRef.current = newValues(formValuesRef.current);
      } else {
        formValuesRef.current = newValues;
      }
    },
    []
  );

  const contextValue: IAdaptersContext = {
    adapters,
    loading,
    setLoading,
    error,
    setError,
    selectedProvider,
    setSelectedProvider,
    setFormValues,
    getProviderPayload,
    edges,
    setEdges,
    onEdgesChange,
    nodes,
    setNodes,
    onNodesChange,
    dialogConfigRef,
    dialogConfigState,
    setDialogConfigState,
    nodeIdRef,
    clickedHandleIdRef,
    userCountry,
    pathSegments,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    selectedPaymentMethodRef,
    selectedVendorName,
    clearWorkflowCreation,
    newWorkflowTitle,
    setNewWorkflowTitle,
    newWorkflowTitleError,
    setNewWorkflowTitleError,
    selectedPaymentMethodError,
    setSelectedPaymentMethodError,
    addProviderDialogOpen,
    setAddProviderDialogOpen,
    initialProvider,
    selectedAdapterId,
    setSelectedAdapterId,
    targetProviderId,
    isConnected,
    setIsConnected,
    payloadFieldValues,
    setPayloadFieldValues,
    accessedViaHeader,
    setAccessedViaHeader,
    formValuesRef,
    nodesRef,
    edgesRef,
  };

  return <AdaptersContext.Provider value={contextValue}>{children}</AdaptersContext.Provider>;
};

export const useAdapters = () => {
  const context = useContext(AdaptersContext);
  if (!context) {
    throw new Error('useAdapters must be used within an AdaptersProvider');
  }
  return context;
};
