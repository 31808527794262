import { createContext, useMemo } from 'react';

import { AuthContextProps, AuthProviderProps } from './AuthContext.type';
import { useAuthContext } from './useAuthContext';

export const AuthContext = createContext({} as AuthContextProps);

export function AuthProvider({ children }: Readonly<AuthProviderProps>) {
  const { authorization } = useAuthContext();

  const value = useMemo(() => ({ authorization }), [authorization]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
