import { Button, TextButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ActionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  height: '26rem',
  overflow: 'hidden',
});

export const TabSelection = styled('ul', {
  display: 'flex',
  width: '16rem',
  flexDirection: 'column',
  listStyle: 'none',
  padding: '0',
  margin: '3px 0 0 0',
  paddingTop: '$4',
  borderRight: '1px solid',
  borderColor: '$interfaceSurfaceSecondary',
  overflow: 'auto',

  li: {
    borderBottom: '1px solid rgba(219, 218, 218, 1)',
  },
});

export const TabContent = styled('div', {
  width: '32rem',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%',
  marginTop: '19px',
  marginBottom: '$6',

  '::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
  // Firefox scrollbar styles
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1)',
  msOverflowStyle: 'auto', // IE and Edge
});

export const contentCss = {
  display: 'flex',
  padding: '0px',
  marginBottom: '$6',
  borderBottom: '1px solid',
  borderColor: '$interfaceSurfaceSecondary',
};

export const ClearFilterTextButton = styled(TextButton, {
  color: '$semanticInfoText',
  transition: 'color 0.3s ease',
  backgroundColor: '#f0f0f0',
  borderRadius: '20px',
  padding: '8px 16px',
  '&:hover': {
    color: '$interfaceLabelPrimary',
  },
  '&.disabled': {
    color: '$interfaceLabelDisabled',
  },
});

export const ButtonFilter = styled(Button, {
  display: 'flex',
  gap: '0.3rem',
  '& > div': {
    background: 'transparent',
    marginRight: 0,
  },
});
