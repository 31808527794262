import { Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { IDayOfTheWeekProps } from 'types/schedule';
import * as S from './DayOfTheWeek.styles';

export const DayOfTheWeek = ({ value, onChange, ...rest }: IDayOfTheWeekProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const daysOftheWeek = [
    {
      id: 1,
      name: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.MONDAY' }),
      value: 'MONDAY',
    },
    {
      id: 2,
      name: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.TUESDAY' }),
      value: 'TUESDAY',
    },
    {
      id: 3,
      name: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.WEDNESDAY' }),
      value: 'WEDNESDAY',
    },
    {
      id: 4,
      name: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.THURSDAY' }),
      value: 'THURSDAY',
    },
    {
      id: 5,
      name: formatMessage({ id: 'resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.FRIDAY' }),
      value: 'FRIDAY',
    },
  ];

  const handleChange = (selectedValue: string) => {
    if (!daysOftheWeek.some((day) => day.value === selectedValue)) {
      console.error(`Unexpected value: ${selectedValue}`);
      return;
    }
    onChange(selectedValue);
  };

  return (
    <S.Container>
      <Select.Root
        shape="square"
        data-testid="single-select"
        placeholder={formatMessage({ id: 'resolvedRequestsPage.scheduleNew.selectAoption' })}
        onChange={handleChange}
        {...rest}
      >
        {daysOftheWeek.map((item) => (
          <Select.Option key={item.id} value={item.value}>
            {item.name}
          </Select.Option>
        ))}
      </Select.Root>
    </S.Container>
  );
};
