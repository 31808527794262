import { createScheduleDataMutation } from './createSchedule';
import { deleteScheduleDataMutation } from './deleteSchedule';
import { downloadScheduleMutation } from './downloadSchedule';
import { DELETE_SCHEDULED_REPORT } from './deleteScheduledReport';
import { CREATE_SCHEDULE_REPORT } from './createScheduleReport';
import { SEND_NOW_REPORT } from './sendNowReport';

export { createScheduleDataMutation, deleteScheduleDataMutation, downloadScheduleMutation };

// New mutations v2
export default {
  DELETE_SCHEDULED_REPORT,
  CREATE_SCHEDULE_REPORT,
  SEND_NOW_REPORT,
};
