import { SearchField } from '@hexa-ui/components';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFilter } from 'contexts/FilterContext';
import * as S from './Search.styles';

const Search = ({ onClearSearch, onSearch }): JSX.Element => {
  const { searchLabel, setSearchLabel } = useFilter();
  const { formatMessage } = useIntl();
  const [searchInput, setSearchInput] = useState<string>('');

  const handleSearchFieldChange = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const onClearSearchInput = useCallback(() => {
    onClearSearch();
    setSearchInput('');
  }, []);

  return (
    <S.SearchFieldWrapper className="search-field-wrapper-input">
      <SearchField.Root
        data-testid="search-field"
        width="22.75rem"
        height="3rem"
        onClear={onClearSearchInput}
        onSearch={onSearch}
        value={searchInput}
        onChange={(e) => handleSearchFieldChange((e.target as HTMLInputElement).value)}
        placeholder={formatMessage({ id: 'resolvedRequestsPage.commom.search' })}
        maxLength={36}
      >
        <SearchField.Filter value={searchLabel} onChange={setSearchLabel}>
          <SearchField.FilterOption value="accountId">
            {formatMessage({ id: 'table.headers.accountId' })}
          </SearchField.FilterOption>
          <SearchField.FilterOption value="paymentId">
            {formatMessage({ id: 'table.headers.paymentId' })}
          </SearchField.FilterOption>
        </SearchField.Filter>
      </SearchField.Root>
    </S.SearchFieldWrapper>
  );
};

export default Search;
