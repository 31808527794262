import { styled } from '@hexa-ui/theme';

export const ChipsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  flexWrap: 'wrap',
  padding: '0 $6',
  marginBottom: '$4',
  alignItems: 'center',
  justifyContent: 'flex-start',

  'div[data-testid="chip-container"]': {
    backgroundColor: 'rgba(20, 20, 20, 0.04)',
  },

  button: {
    transition: 'background-color color 0.3s ease',

    color: '$semanticInfoText',
    '&:hover': {
      color: '$interfaceLabelPrimary',
    },
  },
});
