import { ColumnProps } from 'types/editColumns';

export const commonColumns: ColumnProps[] = [
  {
    Header: 'Payment ID',
    accessor: 'paymentId',
    disableSortBy: false,
    key: 'PAYMENT_ID',
  },
  {
    Header: 'Order ID',
    accessor: 'orderNumber',
    disableSortBy: true,
    key: 'ORDER_NUMBER',
  },
  {
    Header: 'Order value',
    accessor: 'orderValue',
    disableSortBy: true,
    key: 'ORDER_VALUE',
  },
  {
    Header: 'Seller ID',
    accessor: 'sellerId',
    disableSortBy: true,
    key: 'SELLER_ID',
  },
  {
    Header: 'Invoice date',
    accessor: 'invoiceDate',
    disableSortBy: true,
    key: 'INVOICE_DATE',
  },
  {
    Header: 'POC ID',
    accessor: 'accountId',
    disableSortBy: true,
    key: 'ACCOUNT_ID',
  },
  {
    Header: 'Invoice ID',
    accessor: 'invoiceNumber',
    disableSortBy: true,
    key: 'INVOICE_NUMBER',
  },
  {
    Header: 'Payment value',
    accessor: 'paymentValue',
    disableSortBy: true,
    key: 'PAYMENT_VALUE',
  },
  {
    Header: 'Payment status',
    accessor: 'paymentStatus',
    disableSortBy: true,
    key: 'PAYMENT_STATUS',
  },
  {
    Header: 'Order status',
    accessor: 'orderStatus',
    disableSortBy: true,
    key: 'ORDER_STATUS',
  },
  {
    Header: 'Invoice value',
    accessor: 'invoiceValue',
    disableSortBy: true,
    key: 'INVOICE_VALUE',
  },
  {
    Header: 'Invoice status',
    accessor: 'invoiceStatus',
    disableSortBy: true,
    key: 'INVOICE_STATUS',
  },
  {
    Header: 'Creation date',
    accessor: 'paymentDate',
    disableSortBy: false,
    key: 'PAYMENT_DATE',
  },
  {
    Header: 'Method',
    accessor: 'paymentMethod',
    disableSortBy: true,
    key: 'PAYMENT_METHOD',
  },
  {
    Header: 'Service provider',
    accessor: 'paymentMethodCode',
    disableSortBy: true,
    key: 'PAYMENT_METHOD_CODE',
  },
  {
    Header: 'Beneficiary name',
    accessor: 'beneficiaryName',
    disableSortBy: true,
    key: 'BENEFICIARY_NAME',
  },
  {
    Header: 'Beneficiary ID',
    accessor: 'beneficiaryId',
    disableSortBy: true,
    key: 'BENEFICIARY_ID',
  },
  {
    Header: 'Payment method fee',
    accessor: 'paymentMethodFee',
    disableSortBy: true,
    key: 'PAYMENT_METHOD_FEE',
  },
  {
    Header: 'Commission fee',
    accessor: 'commissionFee',
    disableSortBy: true,
    key: 'COMMISSION_FEE',
  },
];
