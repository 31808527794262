import { styled } from '@hexa-ui/theme';

import { Modal } from '@material-ui/core';

export const ModalContainer = styled(Modal, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WrapperContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 'var(--fontSizes-7)',
  height: '520px',

  '@media (max-width: 700px)': {
    flexDirection: 'column',
  },
});

export const WrapperFrequency = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const WrapperItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 'var(--fontSizes-2)',
  margin: '10px 0',
});

export const WrapperFilters = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--fontSizes-6)',
  overflow: 'auto',
  height: '430px',
  width: '100%',
  marginTop: 'var(--fontSizes-5)',
  scrollbarColor: '#DBDADA transparent',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar': {
    height: '18px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '$4',
  },
  '&::-webkit-scrollbar-button': {
    display: 'none',
  },
});

export const WrapperMessage = styled('div', {
  '.c-bSlSqc': {
    width: '100%',
    padding: 'var(--fontSizes-2)',
    borderRadius: 'var(--fontSizes-3)',

    '.c-duLxUO': {
      justifyContent: 'center',
      alignItems: 'center',
      gap: 'var(--fontSizes-2)',
    },
  },
});
export const Box = styled('div', {
  gap: '$2',
});

export const WrapperBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--fontSizes-5)',
  height: '420px',
});

export const width = {
  width: '0.18rem',
  height: 'auto !important',
};
