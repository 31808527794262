import { gql } from '@apollo/client';

export const GET_SCHEDULED_REPORT_DATA = gql`
  query GetSchedulesCountry($input: GetSchedulesCountryInputType!) {
    getSchedulesCountry(input: $input) {
      result {
        id
        downloadHour
        fileType
        status
        userRequest
        country
        columnFilters
        description
        deleted
        creationDate
        updateDate
        searchFilters {
          buyerId
          invoiceNumber
          invoiceStatus
          orderNumber
          paymentDue
          paymentMethod
          paymentStatus
          orderStatus
        }
        downloadDaysOfWeek
        fileName
      }
    }
  }
`;
