import { styled } from '@hexa-ui/theme';

export const Item = styled('div', {
  fontFamily: 'Work Sans',
  padding: '0 10px',
  display: 'flex',
  fontWeight: 500,
  justifyContent: 'space-between',
  borderRadius: '8px',
  marginBottom: '3px',
  alignItems: 'center',
  height: '48px',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(230, 242, 255, 0.5)',
  },
  '&.selected': {
    backgroundColor: 'rgba(230, 242, 255, 1)',
  },
  '&.disabled': {
    fontWeight: 400,
    opacity: 0.5,
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
  },
  '& label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: '0 12px',
  },
  '& input[type="checkbox"]': {
    display: 'none',
  },
  '& .checkmark': {
    height: '16px',
    width: '16px',
    backgroundColor: 'rgba(20, 20, 20, 0.08)',
    borderRadius: '4px',
    position: 'relative',
  },
  '& input[type="checkbox"]:checked + .checkmark': {
    backgroundColor: 'rgba(20, 20, 20, 1)',
  },
  '& .checkmark:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    left: '5px',
    top: '3px',
    width: '4px',
    height: '6px',
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
  '& input[type="checkbox"]:checked + .checkmark:after': {
    display: 'block',
  },
  '&.disabled .checkmark': {
    backgroundColor: 'rgba(20, 20, 20, 0.08)',
  },
  '&.disabled .checkmark:after': {
    display: 'block',
    borderColor: 'darkgray',
  },
});
