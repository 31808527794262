import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  borderRadius: '1rem',
  padding: '4px 12px',
  border: '1px solid',
  variants: {
    variant: {
      canceled: { borderColor: '#DECDBA', color: '#665339' },
      expired: { borderColor: '#DBDADA', color: '#585858' },
      delivered: { borderColor: '#99E699', color: '#009900' },
      inProgress: { borderColor: '#A1D0FF  ', color: '#006EDB' },
      partiallyDelivered: { borderColor: '#FFD499', color: '#D47300' },
      orderPlaced: { borderColor: '#FFD499', color: '#D47300' },
      outForDelivery: { borderColor: '#A1D0FF', color: '#006EDB' },
      paymentPending: { borderColor: '#FFD499', color: '#D47300' },
      paid: { borderColor: '#99E699', color: '#009900' },
      failed: { borderColor: '#FFBABA', color: '#E82727' },
      error: { borderColor: '#FFBABA', color: '#E82727' },
      // approved: { borderColor: '$semanticSuccessText', color: '$semanticSuccessText' },
      // rejected: { borderColor: '$semanticErrorText', color: '$semanticErrorText' },
      // pending: { borderColor: '$brandAccentWarmBasis', color: '$brandAccentWarmBasis' },
    },
  },
});

export const StyledStatus = styled('div', {
  width: '0.5rem',
  height: '0.5rem',
  background: 'green',
  borderRadius: '50%',
  variants: {
    variant: {
      canceled: { background: '#665339' },
      paid: { background: '#009900' },
      expired: { background: '#585858' },
      delivered: { background: '#009900' },
      inProgress: { background: '#006EDB' },
      outForDelivery: { background: '#006EDB' },
      partiallyDelivered: { background: '#D47300' },
      orderPlaced: { background: '#D47300' },
      paymentPending: { borderColor: '#D47300' },
      failed: { background: '#E82727' },
      error: { background: '#E82727' },
      // approved: { background: '$semanticSuccessText' },
      // rejected: { background: '$semanticErrorText' },
      // pending: { background: '$brandAccentWarmBasis' },
    },
  },
});

export const StatusText = styled('span', {
  fontFamily: '$paragraph',
  fontSize: '0.875rem',
});
