import { styled } from '@hexa-ui/theme';

export const DropdownContainer = styled('div', {});

const commonStyles = {
  fontFamily: 'Work Sans',
};

export const Dropdown = styled('div', {
  position: 'relative',
  display: 'inline-block',
});

export const DropdownButton = styled('button', {
  ...commonStyles,
  fontWeight: 500,
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '20px',
  padding: '8px 15px',
  fontSize: '1rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

export const DropdownContent = styled('div', {
  width: '328px',
  display: 'block',
  position: 'absolute',
  backgroundColor: '#fff',
  minWidth: '160px',
  overflow: 'auto',
  borderRadius: '8px',
  padding: '3px',
  zIndex: 1000,
  right: 0,
  maxHeight: '300px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(219, 218, 218, 1)',
  },
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
  // Firefox scrollbar styles
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1)',
  msOverflowStyle: 'auto', // IE and Edge
});

export const Item = styled('div', {
  ...commonStyles,
  padding: '12px 10px',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px',
  marginBottom: '3px',
  alignItems: 'center',
  '&.selected': {
    backgroundColor: 'rgba(230, 242, 255, 1)',
  },
  '&.disabled': {
    opacity: 0.5,
  },
  '& label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& input[type="checkbox"]': {
    display: 'none',
  },
  '& .checkmark': {
    height: '16px',
    width: '16px',
    backgroundColor: 'rgba(20, 20, 20, 0.08)',
    borderRadius: '4px',
    position: 'relative',
  },
  '& input[type="checkbox"]:checked + .checkmark': {
    backgroundColor: 'rgba(20, 20, 20, 1)',
  },
  '& .checkmark:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    left: '5px',
    top: '3px',
    width: '4px',
    height: '6px',
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
  '& input[type="checkbox"]:checked + .checkmark:after': {
    display: 'block',
  },
});
