import { styled } from '@hexa-ui/theme';
import { EmptyState } from '@hexa-ui/components';

export const Container = styled('div', {
  '&.table-request-container': {
    '& > div': {
      height: '100vh',
    },
    '& > div > div': {
      overflowY: 'hidden',
    },
  },

  '&.pagination-container-empty > div > div > table > tbody > tr > td > div ': {
    height: '100vh',
  },
});

export const EmptyStateWrapper = styled(EmptyState.SectionLevel, {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: {
    width: '200px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
});
