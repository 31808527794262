import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModalContext } from 'contexts';
import { fileFormatValues } from 'pages/Reports/components/DownloadModal/formats';
import { OptionProps } from 'pages/Reports/components/DownloadModal/types';
import decodeToken from 'utils/decodeToken';

export function useDownloadModal() {
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm();
  const [fileFormat, setFileFormat] = useState<OptionProps[]>(fileFormatValues);

  const { downloadModal } = useContext(ModalContext);

  const handleSelectFileFormat = (name: string) => {
    const newFileFormat = fileFormat.map((item) => {
      return {
        ...item,
        value: item.name === name,
      };
    });
    setFileFormat(newFileFormat);
  };

  const onSubmit = () => {
    try {
      const downloadFileFormat = fileFormat[0].key;
      const userDecodeToken = decodeToken();
      const email = userDecodeToken.email;

      downloadModal.handleSubmitDownload({
        emailAddress: email,
        fileType: downloadFileFormat,
      });
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };
  return { register, handleSubmit, isValid, isDirty, onSubmit, fileFormat, handleSelectFileFormat };
}
