import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from './i18n.d';
import LanguageContext from './LanguageContext';
import en from './languages/en';
import es from './languages/es';
import ptBr from './languages/pt-br';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};
const messages: { [language: string]: MessageMap } = {
  'en-US': en,
  'pt-BR': ptBr,
  'es-419': es,
};

const IntlProvider = ({ children }: IIntlProvider): JSX.Element => {
  const [language, setLanguage] = useState('en-US');

  const preferredLanguage = usePreferredLanguageV2();

  const preferredLang = useMemo(() => preferredLanguage?.preferredLanguage, [preferredLanguage]);

  useEffect(() => {
    if (preferredLang) {
      setLanguage(preferredLang);
    } else {
      setLanguage('en-US');
    }
  }, [preferredLang]);

  const contextValue = useMemo(() => ({ language, setLanguage }), [language, setLanguage]);

  const mergedMessages = mergeMessages(messages, language);

  return (
    <LanguageContext.Provider value={contextValue}>
      <NativeProvider locale={language} defaultLocale={language} messages={mergedMessages}>
        {children}
      </NativeProvider>
    </LanguageContext.Provider>
  );
};

export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
