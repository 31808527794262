import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

import styledComponent from 'styled-components';

export const FilterWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5em',
});

export const WrapperHideOrShowColumns = styledComponent.div`
  width: 280px;
`;

export const FilterTitle = styled(Heading, {});
