import { Countries } from 'enums/payments';
import moment from 'moment-timezone';
import { locales } from './locales';

export const formatValue = (value: number) => {
  if (!value) return null;

  return value.toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
  });
};

export const formatDate = (date: Date) => {
  const formatDate = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  const isValidDate = moment(date, formatDate, true);

  if (!isValidDate.isValid()) {
    return '-';
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  return moment(date).tz(timeZone).format('MMM D HH:mm:ss');
};

export const formatCurrency = (value: string, currency?: string) => {
  const countriesWithoutCents = [Countries.CO];

  const numberValue = Number(value);

  const { code: countryCode, country } = locales[currency] || {};

  if (isNaN(numberValue) || !countryCode) return value;

  const removeCents = countriesWithoutCents.includes(country);

  const [{ value: currencySymbol }, ...formattedParts] = Intl.NumberFormat(countryCode, {
    style: 'currency',
    maximumFractionDigits: removeCents ? 0 : 2,
    currency,
  }).formatToParts(removeCents ? Math.floor(numberValue) : numberValue);

  let formattedValue = '';

  formattedParts.forEach((part) => {
    if (!part.value.match(/^\s*$/g)) {
      formattedValue += part.value;
    }
  });

  return `${currency} ${currencySymbol} ${formattedValue}`;
};
