import { useMemo } from 'react';
import { Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import * as S from './Time.styles';
import { formatTimeNumber } from 'utils/date';
import { ITimeProps } from 'types/time';

export const Time = ({ value, onChange, ...rest }: ITimeProps) => {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();

  const options = useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => formatTimeNumber(i, preferredLanguage || 'en'));
  }, [preferredLanguage]);

  const handleChange = (selectedValue: string) => {
    try {
      onChange(selectedValue);
    } catch (error) {
      console.error('Error handling change:', error);
    }
  };

  return (
    <S.Container>
      <Select.Root
        width="50%"
        shape="square"
        data-testid="single-select"
        placeholder={formatMessage({ id: 'resolvedRequestsPage.scheduleNew.deliveryTime' })}
        onChange={handleChange}
        aria-label="Time"
        {...rest}
      >
        {options.map((item) => (
          <Select.Option key={item.id} value={item.value}>
            {item.name}
          </Select.Option>
        ))}
      </Select.Root>
    </S.Container>
  );
};
