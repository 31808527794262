import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, FetchResult } from '@apollo/client';
import { ToastTypes } from '@hexa-ui/components';
import { TypeToast, useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { AuthContext } from 'contexts';
import graphql from 'gql';
import scheduleToken from 'utils/decodeToken';
import { useFilter } from 'contexts/FilterContext';
import { ICreateScheduleForm, IScheduleVariables, IScheduleData } from 'types/schedule';
import { formatDateForRequest } from 'utils/date';

export const useModalSchedule = (handleCloseModal: () => void) => {
  const { CREATE_SCHEDULE_REPORT } = graphql.transactions.mutations;
  const { authorization } = useContext(AuthContext);
  const { preferredLanguage } = usePreferredLanguageV2();
  const [{ selectedCountry }] = useAppHeader();
  const {
    paymentStatusValues,
    orderStatusValues,
    columnsToShow,
    fromCreatedAtValue,
    toCreatedAtValue,
  } = useFilter();
  const { formatMessage } = useIntl();
  const [isError, setIsError] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastTypes>({
    type: TypeToast.ERROR,
    open: false,
    position: 'top-right',
    onOpenChange: () => false,
    message: '',
  });
  const tokenBeesHub = localStorage.getItem('authHeader');
  const userScheduleToken = scheduleToken();
  const email = userScheduleToken?.email;

  const validOrderStatusValues = Array.isArray(orderStatusValues) ? orderStatusValues : [];
  const validPaymentStatusValues = Array.isArray(paymentStatusValues) ? paymentStatusValues : [];
  const validColumnsToShow = Array.isArray(columnsToShow) ? columnsToShow : [];

  const context = useMemo(
    () => ({
      headers: {
        authorization: authorization ?? '',
        authorizationHub: tokenBeesHub ?? '',
      },
    }),
    [authorization, tokenBeesHub]
  );

  const showToast = (type: TypeToast, messageId: string): void => {
    setToast({
      type,
      open: true,
      position: 'top-right',
      onOpenChange: handleToastOpenChange,
      message: formatMessage({ id: messageId }),
    });
  };

  const handleToastOpenChange = (value: boolean) => {
    setToast((prevState) => ({
      ...prevState,
      open: value,
    }));
  };

  const showErrorToast = () =>
    showToast(TypeToast.ERROR, 'resolvedRequestsPage.scheduleNew.errorCreate');
  const showSuccessToast = () =>
    showToast(TypeToast.SUCCESS, 'resolvedRequestsPage.scheduleNew.successCreate');

  const orderDateFrom = formatDateForRequest(fromCreatedAtValue, preferredLanguage);
  const orderDateTo = formatDateForRequest(toCreatedAtValue, preferredLanguage);
  const orderStatus = validOrderStatusValues.map((status) => status.value);
  const paymentStatus = validPaymentStatusValues.map((status) => status.value);
  const fieldsToExport = validColumnsToShow.map((column) => column.key);

  const [mutateFunction, { loading }] = useMutation(CREATE_SCHEDULE_REPORT, {
    context,
    onCompleted: () => {
      showSuccessToast();
      handleCloseModal();
    },
    onError: (error) => {
      setIsError(true);
      console.error('Error creating schedule:', error);
      showErrorToast();
    },
  });

  const getScheduleVariables = (values: ICreateScheduleForm): IScheduleVariables => ({
    input: {
      fieldsToExport,
      emailAddress: email,
      downloadDaysOfWeek: [values.dayOfTheWeek],
      downloadHour: values.time,
      country: selectedCountry,
      fileName: values.reportName,
      paymentStatus: paymentStatus.length ? paymentStatus : undefined,
      orderStatus: orderStatus.length ? orderStatus : undefined,
      orderDateFrom,
      orderDateTo,
    },
  });

  const createSchedule = async (
    values: ICreateScheduleForm
  ): Promise<FetchResult<IScheduleData>> => {
    const variables = getScheduleVariables(values);
    try {
      const result = await mutateFunction({ variables });
      return result;
    } catch (error) {
      setIsError(true);
      console.error('Error creating schedule:', error);
      showErrorToast();
    }
  };

  return { createSchedule, loading, isError, toast, handleToastOpenChange, setToast };
};
