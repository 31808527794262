// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container-empty [data-testid='pagination-container'] > div {
  display: flex;
  justify-content: flex-end;
}

.pagination-container-empty [data-testid='pagination-container'] > div > p {
  visibility: hidden;
  position: relative;
}

.pagination-container-empty [data-testid='pagination-container'] > div > p::after {
  content: '0 - 0 of 0';
  visibility: visible;
  position: absolute;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  color: var(--colors-interfaceLabelDisabled);
  left: -10px;
  top: 2px;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/TableRequest/TableRequest.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,2CAA2C;EAC3C,WAAW;EACX,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".pagination-container-empty [data-testid='pagination-container'] > div {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.pagination-container-empty [data-testid='pagination-container'] > div > p {\n  visibility: hidden;\n  position: relative;\n}\n\n.pagination-container-empty [data-testid='pagination-container'] > div > p::after {\n  content: '0 - 0 of 0';\n  visibility: visible;\n  position: absolute;\n  display: inline;\n  font-family: inherit;\n  font-size: inherit;\n  color: var(--colors-interfaceLabelDisabled);\n  left: -10px;\n  top: 2px;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
