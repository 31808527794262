import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts';
import { useFetch } from 'core/hooks/useFetch';
import { SVariables } from 'pages/Reports/types/Reports.types';
import { getScheduledCountryQuery } from 'gql/features/reports/queries';
import { useEnvContext } from 'utils/EnvProvider/EnvProvider';
import { reportSelectedCountry } from 'store/selectors/report.selectors';
import { useSelector } from 'react-redux';

export function useScheduled() {
  const { REACT_APP_API_BFF } = useEnvContext();
  const { authorization } = useContext(AuthContext);
  const [scheduledData, setScheduledData] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const selectedCountryGlobalState = useSelector(reportSelectedCountry);

  const scheduleVariables: SVariables = {
    input: { token: localStorage.getItem('authHeader') },
  };

  const { data, isLoading, refetch } = useFetch({
    key: ['scheduled-reports'],
    graph: `${REACT_APP_API_BFF}`,
    query: getScheduledCountryQuery,
    country: selectedCountryGlobalState,
    variables: scheduleVariables,
    authorization: `${authorization}`,
    authorizationHub: localStorage.getItem('authHeader'),
  });

  useEffect(() => {
    if (data) {
      setScheduledData(data['getSchedulesCountry']?.result);
      setTotalPages(data['getSchedulesCountry']?.result.length);
    }
  }, [data]);

  return {
    scheduledData,
    isLoading,
    totalPages,
    refetch,
  };
}
