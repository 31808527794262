import { DisplayColumns } from 'components/DisplayColumns';
import { FilterTitle, FilterWrapper, WrapperHideOrShowColumns } from './FilterOrder.styles';

export function FilterOrder({ columns, onChange }) {
  return (
    <FilterWrapper>
      <FilterTitle size="H3">Results preview</FilterTitle>

      <WrapperHideOrShowColumns>
        <DisplayColumns columns={columns} onChange={onChange} />
      </WrapperHideOrShowColumns>
    </FilterWrapper>
  );
}
