import { getCreditdataQuery } from './getGatewayReportDataResolver';
import { getScheduledCountryQuery } from './getSchedulesCountry';

// new queries
import { GET_GATEWAY_REPORT_DATA } from './getGatewayReportData';
import { GET_SCHEDULED_REPORT_DATA } from './getScheduledReportData';

export { getCreditdataQuery, getScheduledCountryQuery };

export default {
  GET_GATEWAY_REPORT_DATA,
  GET_SCHEDULED_REPORT_DATA,
};
