import styled from 'styled-components';

export const ScheduleModalModalWrapper = styled.div`
  .c-ktUxKa-eYaSdF-shouldAdjustPadding-true {
    padding: 1rem 2rem 0 2rem;
  }
`;

export const ScheduleModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--fonts-heading);
`;

export const ScheduleModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ScheduleModalHeaderText = styled.div`
  line-height: 15px;
`;

export const ScheduleModalForm = styled.form``;

export const ScheduleModalToggleField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;

  .c-bvjfnc[data-state='checked'] {
    background-color: #05b605;
  }
`;

export const ScheduleFormFieldGroup = styled.div`
  margin: 15px 0;
`;

export const ScheduleModalActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

export const ScheduleModalFileFormats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
`;

export const ScheduleModalFileFormatsItem = styled.div<{
  selected: boolean;
}>`
  border: ${({ selected }) => {
    return selected ? ' 1px solid #d7f0ff' : '1px solid #cbcbcb';
  }};
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ selected }) => {
    return selected ? '#d7f0ff' : null;
  }};

  color: ${({ selected }) => {
    return selected ? '#1999e1' : null;
  }};

  .c-gzQrVK-bfAFXs-size-large {
    width: 16px;
    height: 16px;
  }

  :hover {
    background-color: #d7f0ff;
    border: 1px solid transparent;
    color: #1999e1;
  }
`;
