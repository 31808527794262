import { ListItem as ListItemComponent } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useCallback } from 'react';
import { ChevronRight } from '@hexa-ui/icons';

// const ChevronDoubleRightIcon = React.memo(() => <ChevronDoubleRight size="medium" />);

const ListItem = styled(ListItemComponent, {
  variants: {
    active: {
      true: {
        backgroundColor: 'rgba(246, 242, 255, 1)',
      },
      false: {},
    },
  },
});

const FilterTabContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

interface FilterTabProps {
  tab?: {
    id: string;
    name: string;
  };
  index?: number;
  setStateFunction?: (tabIndex: number) => void;
  activeTab?: number;
}

export const FilterTab = ({ tab, index, activeTab, setStateFunction }: FilterTabProps) => {
  const handleTabChange = useCallback(() => {
    setStateFunction(index);
  }, []);

  return (
    <ListItem
      variant="selectable"
      active={index === activeTab}
      spacing="medium"
      data-testid={`filter-tab-${tab.id}`}
      key={`tab-${tab.id}`}
      onClick={handleTabChange}
      className="filter-tab"
    >
      <FilterTabContainer className="filter-tab-container">
        {tab.name} <ChevronRight size="medium" />
      </FilterTabContainer>
    </ListItem>
  );
};
