import { styled } from '@hexa-ui/theme';

export const PaginationContainer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px',
});
