import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#14141414',
  borderRadius: '50%',
  width: '24px',
  height: '24px',

  '&:hover': {
    backgroundColor: '$neutral30',
  },
});

export const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
