import { IOption } from 'types/filterBar';

export const paymentStatusOptions: IOption[] = [
  {
    idName: 'paymentStatusExpired',
    value: 'EXPIRED',
    label: 'Expired',
  },
  {
    idName: 'paymentStatusFailed',
    value: 'FAILED',
    label: 'Failed',
  },
  {
    idName: 'paymentStatusPaid',
    value: 'PAID',
    label: 'Paid',
  },
];

export const orderStatusOptions: IOption[] = [
  {
    idName: 'orderStatusAvailable',
    value: 'AVAILABLE',
    label: 'Available',
  },
  {
    idName: 'orderStatusCancelled',
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  {
    idName: 'orderStatusConfirmed',
    value: 'CONFIRMED',
    label: 'Confirmed',
  },
  {
    idName: 'orderStatusDelivered',
    value: 'DELIVERED',
    label: 'Delivered',
  },
  {
    idName: 'orderStatusDenied',
    value: 'DENIED',
    label: 'Denied',
  },
  {
    idName: 'orderStatusExpired',
    value: 'EXPIRED',
    label: 'Expired',
  },
  {
    idName: 'orderStatusInvoiced',
    value: 'INVOICED',
    label: 'Invoiced',
  },
  {
    idName: 'orderStatusInTransit',
    value: 'IN_TRANSIT',
    label: 'In transit',
  },
  {
    idName: 'orderStatusModified',
    value: 'MODIFIED',
    label: 'Modified',
  },
  {
    idName: 'orderStatusPaid',
    value: 'PAID',
    label: 'Paid',
  },
  {
    idName: 'orderStatusPartialDelivery',
    value: 'PARTIAL_DELIVERY',
    label: 'Partial delivery',
  },
  {
    idName: 'orderStatusPending',
    value: 'PENDING',
    label: 'Pending',
  },
  {
    idName: 'orderStatusPendingCancellation',
    value: 'PENDING_CANCELLATION',
    label: 'Pending cancelation',
  },
  {
    idName: 'orderStatusPendingPayment',
    value: 'PENDING_PAYMENT',
    label: 'Pending Payment',
  },
  {
    idName: 'orderStatusPlaced',
    value: 'PLACED',
    label: 'Placed',
  },
];
