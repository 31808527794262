import { format, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';

export const dropdownOptionsHour = Array.from({ length: 24 }, (_, i) => {
  const hour = setMilliseconds(setSeconds(setMinutes(setHours(new Date(), i), 0), 0), 0);
  const id = format(hour, 'HH:mm');
  const name = format(hour, 'HH:mm');
  return { id, name };
});

export const daysOfWeekValues = [
  {
    name: 'Mon',
    key: 'MONDAY',
    value: false,
  },
  {
    name: 'Tue',
    key: 'TUESDAY',
    value: false,
  },
  {
    name: 'Wed',
    key: 'WEDNESDAY',
    value: false,
  },
  {
    name: 'Thu',
    key: 'THURSDAY',
    value: false,
  },
  {
    name: 'Fri',
    key: 'FRIDAY',
    value: false,
  },
];

export const fileFormatValues = [
  {
    name: '.csv',
    key: 'CSV',
    value: false,
  },
  // {
  //   name: '.txt',
  //   key: 'TXT',
  //   value: false,
  // },
];
