import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDown, ChevronUp, Columns } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import * as S from './EditColumnsDropdown.styles';
import { columnsHideShowDropDown } from 'constants/columnsHideShowDropDown';
import { ItemCheckbox } from 'components/ItemCheckbox/ItemCheckbox';
import { reportSelectedUserViewer } from 'store/selectors/report.selectors';
import { managerTypeUser } from 'constants/managerUserViewerType';
import { translateArray } from 'utils/translateArray';

const ChevronDownIcon = React.memo(() => <ChevronDown size="medium" />);
const ChevronUpIcon = React.memo(() => <ChevronUp size="medium" />);

export const EditColumnsDropdown = ({ onChange }) => {
  const { formatMessage } = useIntl();
  const typeUser = useSelector(reportSelectedUserViewer);

  const translatedList = useMemo(
      () => translateArray(columnsHideShowDropDown, formatMessage),
      [formatMessage]
    );

  const allowedColumns = Array.isArray(managerTypeUser)
    ? managerTypeUser.find((userType) => typeUser?.includes(userType.type))?.key || []
    : [];

  const filteredColumns = translatedList.filter((column) =>
    allowedColumns.includes(column.key)
  );

  const disabledColumns = translatedList
    .filter((column) => column.disabled)
    .map((column) => column.key);

  const initialSelectedColumns = translatedList
    .filter((column) => !column.disabled)
    .map((column) => column.key)
    .concat(disabledColumns);

  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectAll = useCallback(() => {
    let newSelectedColumns: string[];
    if (selectedColumns.length === translatedList.length) {
      newSelectedColumns = disabledColumns;
    } else {
      newSelectedColumns = translatedList.map((column) => column.key);
    }
    setSelectedColumns(newSelectedColumns);
    setTimeout(() => onChange(newSelectedColumns), 0);
  }, [selectedColumns, disabledColumns, onChange]);

  const handleSelectItem = useCallback(
    (key) => {
      if (disabledColumns.includes(key)) {
        return;
      }

      setSelectedColumns((currentSelected) => {
        const newSelected = currentSelected.includes(key)
          ? currentSelected.filter((item) => item !== key)
          : [...currentSelected, key];
        setTimeout(() => onChange(newSelected), 0);
        return newSelected;
      });
    },
    [disabledColumns, onChange]
  );

  const handleToggleDropdown = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const allSelected = selectedColumns.length === translatedList.length;

  return (
    <S.DropdownContainer ref={dropdownRef}>
      <S.Dropdown>
        <S.DropdownButton onClick={handleToggleDropdown}>
          <Columns /> {formatMessage({ id: 'resolvedRequestsPage.editColumnsDropdown.title' })}
          {dropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </S.DropdownButton>
        {dropdownOpen && (
          <S.DropdownContent>
            <ItemCheckbox
              label={formatMessage({ id: 'resolvedRequestsPage.editColumnsDropdown.selectAll' })}
              checked={allSelected}
              onChange={handleSelectAll}
              disabled={false}
            />
            {filteredColumns.map((column) => (
              <ItemCheckbox
                key={column.key}
                label={column.label}
                checked={selectedColumns.includes(column.key)}
                onChange={() => handleSelectItem(column.key)}
                disabled={column.disabled}
              />
            ))}
          </S.DropdownContent>
        )}
      </S.Dropdown>
    </S.DropdownContainer>
  );
};
