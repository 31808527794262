import {
  Button,
  Divider,
  IconButton,
  Modal as ModalComponent,
  Toast as ToastComponent,
} from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as S from './ModalSchedule.styles';
import { useModalSchedule } from './hooks';

import { ICreateScheduleForm, IScheduleProps } from 'types/schedule';
import { getSelectedFilters } from 'utils/getSelectedFilters';
import { useUserEmail } from 'utils/useUserEmail/useUserEmail';
import { DayOfTheWeek, ReportName, Summary, Time } from './components';

export const ModalSchedule = ({ isOpen, onClose }: IScheduleProps): JSX.Element => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement>(null);
  const { formatMessage } = useIntl();
  const [formState, setFormState] = useState({
    dayOfTheWeek: '',
    time: '',
    reportName: '',
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const userEmail = useUserEmail();

  const resetFormState = () => {
    setFormState({
      dayOfTheWeek: '',
      time: '',
      reportName: '',
    });
    setDisabled(true);
  };

  const handleCloseModal = () => {
    onClose(false);
    resetFormState();
  };

  const { createSchedule, loading, toast, handleToastOpenChange } =
    useModalSchedule(handleCloseModal);

  useEffect(() => {
    if (isOpen) {
      const { dayOfTheWeek, time } = formState;
      setDisabled(!(dayOfTheWeek && time));
    }
  }, [isOpen, formState.dayOfTheWeek, formState.time]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formValues: ICreateScheduleForm = { ...formState };
    try {
      await createSchedule(formValues);
    } catch (error) {
      console.error('Failed to create schedule:', error);
    }
  };

  const { Root: Modal } = ModalComponent;
  const { Root: Toast } = ToastComponent;

  type IHandleChange = (value: string) => void;

  const handleDayOfTheWeekChange: IHandleChange = (value) => {
    setFormState((prev) => ({ ...prev, dayOfTheWeek: value }));
  };

  const handleTimeChange: IHandleChange = (value) => {
    setFormState((prev) => ({ ...prev, time: value }));
  };

  const handleReportNameChange: IHandleChange = (value) => {
    setFormState((prev) => ({ ...prev, reportName: value }));
  };

  return (
    <>
      <Toast {...toast} showCloseButton onClose={() => handleToastOpenChange(false)} />
      <S.ModalWrapper data-testid="schedule">
        <Modal
          open={isOpen}
          actions={null}
          title={
            <S.ContainerHeader>
              <S.Title>
                {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.titleHeader' })}
              </S.Title>
              <IconButton onClick={handleCloseModal} size="small" variant="tertiary" icon={X} />
            </S.ContainerHeader>
          }
          aria-describedby="modal-description"
        >
          <S.StyledForm onSubmit={submitHandler}>
            <S.Description>
              {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.description' })}{' '}
              <strong>{userEmail}</strong>
            </S.Description>
            <S.WrapperContent>
              <S.WrapperLeftBox>
                <S.WrapperBox>
                  <S.Label size="H4">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.settings' })}
                  </S.Label>
                </S.WrapperBox>
                <S.WrapperBox>
                  <S.Label size="H5">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.dayOfTheWeek' })}
                  </S.Label>
                  <DayOfTheWeek
                    value={formState.dayOfTheWeek}
                    onChange={handleDayOfTheWeekChange}
                  />
                </S.WrapperBox>
                <S.WrapperBox>
                  <S.Label size="H5">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.fieldDelivery' })}
                  </S.Label>
                  <Time value={formState.time} onChange={handleTimeChange} />
                </S.WrapperBox>
                <S.WrapperBox>
                  <S.Label size="H5">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.report' })}
                  </S.Label>
                  <ReportName value={formState.reportName} onChange={handleReportNameChange} />
                </S.WrapperBox>
              </S.WrapperLeftBox>
              <Divider orientation="vertical" css={S.width} />
              <S.WrapperLeftBox>
                <S.Label size="H4">
                  {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.filters' })}
                </S.Label>
                <S.WrapperFilters>
                  {getSelectedFilters({ column: 'right', modalType: 'ModalSchedule' }).map(
                    (filter) => (
                      <Summary
                        title={filter.title}
                        description={filter.description}
                        key={filter.id}
                      />
                    )
                  )}
                </S.WrapperFilters>
              </S.WrapperLeftBox>
            </S.WrapperContent>
            <S.footerContainer>
              <S.Box>
                <Button size="medium" variant="secondary" onClick={handleCloseModal}>
                  {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.cancelButton' })}
                </Button>
                <Button
                  ref={firstInteractiveElementRef}
                  size="medium"
                  disabled={disabled || loading}
                  type="submit"
                  isLoading={loading}
                >
                  {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.sendButton' })}
                </Button>
              </S.Box>
            </S.footerContainer>
          </S.StyledForm>
        </Modal>
      </S.ModalWrapper>
    </>
  );
};
