import { styled } from '@hexa-ui/theme';
import styledComponent from 'styled-components';

export const DownloadModalModalWrapper = styled('div', {
  '.c-ktUxKa-eYaSdF-shouldAdjustPadding-true': {
    padding: '1rem 2rem 0 2rem',
  },
});

export const DownloadModalTitleWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'var(--fonts-heading)',
});

export const DownloadModalFileFormatsItem = styledComponent.div<{
  selectedFormat: string;
  currentFormatItem: string;
}>`
  border: ${({ selectedFormat, currentFormatItem }) => {
    return currentFormatItem == selectedFormat ? ' 1px solid #d7f0ff' : '1px solid #cbcbcb';
  }};
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ selectedFormat, currentFormatItem }) => {
    return currentFormatItem == selectedFormat ? '#d7f0ff' : null;
  }};

  color: ${({ selectedFormat, currentFormatItem }) => {
    return currentFormatItem == selectedFormat ? '#1999e1' : null;
  }};

  .c-gzQrVK-bfAFXs-size-large {
    width: 16px;
    height: 16px;
  }

  :hover {
    background-color: #d7f0ff;
    border: 1px solid transparent;
    color: #1999e1;
  }
`;

export const WrapperButtons = styledComponent.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

export const StyledForm = styled('form', {
  height: '100%',
});

export const InputWrapper = styled('section', {
  margin: '$4 0',
});
