import { useIntl } from 'react-intl';

export const useStatusBase = () => {
  const { formatMessage } = useIntl();

  const statuses = [
    { key: 'CANCELED', variant: 'canceled' },
    { key: 'DELIVERED', variant: 'delivered' },
    { key: 'EXPIRED', variant: 'expired' },
    { key: 'FAILED', variant: 'failed' },
    { key: 'ERROR', variant: 'error' },
    { key: 'IN_PROGRESS', variant: 'inProgress' },
    { key: 'ORDER_PLACED', variant: 'orderPlaced' },
    { key: 'OUT_FOR_DELIVERY', variant: 'outForDelivery' },
    { key: 'PAID', variant: 'paid' },
    { key: 'PARTIALLY_DELIVERED', variant: 'partiallyDelivered' },
    { key: 'PAYMENT_PENDING', variant: 'paymentPending' },
    { key: 'PROCESSING', variant: 'processing', statusName: 'Processing' },
    { key: 'WAITING_MERCHANT', variant: 'waitingMerchant', statusName: 'Waiting Merchant' },
    { key: 'WAITING_PROVIDER', variant: 'waitingProvider', statusName: 'Waiting Provider' },
  ];

  const getStatusMessage = (id: string) => {
    try {
      return formatMessage({ id });
    } catch (error) {
      console.error(`Error formatting message for id: ${id}`, error);
      return id;
    }
  };

  return statuses.reduce((acc, { key, variant, statusName }) => {
    acc[key] = {
      variant,
      statusName: statusName || getStatusMessage(`statusOrders.${variant}`),
      tooltipMessage: getStatusMessage(`statusOrders.${variant}Info`),
    };
    return acc;
  }, {});
};
