import { Tooltip } from '@hexa-ui/components';
import { StatusProps } from 'types/status';
import { useStatusBase } from './hooks';
import * as S from './Status.styles';

const Status = ({ hasTooltip = true, fontSizeStatus = '1rem', type }: StatusProps): JSX.Element => {
  const statusBase = useStatusBase();
  const status = statusBase[type] || {
    variant: 'failed',
    statusName: `Not mapped: [${type}]`,
    tooltipMessage: 'Status not mapped',
  };
  const { variant, tooltipMessage, statusName } = status;

  if (!variant) {
    throw new Error(`Invalid status type: ${type}`);
  }

  return (
    <S.Container variant={variant}>
      {hasTooltip ? (
        <Tooltip text={tooltipMessage} placement="top">
          <S.StyledStatus variant={variant} />
        </Tooltip>
      ) : (
        <S.StyledStatus variant={variant} />
      )}
      <S.StatusText css={{ fontSize: fontSizeStatus }}>{statusName}</S.StatusText>
    </S.Container>
  );
};

export { Status };
