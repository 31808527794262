import { useEffect, useState } from 'react';
import decodeToken from 'utils/decodeToken';

export const useUserEmail = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    try {
      const userData = decodeToken();
      if (userData?.email) {
        setUserEmail(userData.email);
      }
    } catch (error) {
      console.error('Failed to decode token', error);
    }
  }, []);

  return userEmail;
};
