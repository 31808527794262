import { LoadingDots } from '@hexa-ui/components';

import { FilterShowResults } from './FilterResults.styles';
import type { TTotal } from './FilterResults.types';

export function FilterResults({ offset, total, isLoading, isRefetching }: Readonly<TTotal>) {
  return (
    <FilterShowResults>
      {isLoading || isRefetching ? (
        <LoadingDots />
      ) : (
        total > 0 && `Showing ${offset || 0} of ${total} results. Send report for full list.`
      )}
    </FilterShowResults>
  );
}
