import { Button } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  padding: '1rem 1.5rem',
  '& > :nth-child(2)': {
    marginLeft: 'auto',
  },
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'start',
});

export const ButtonFilter = styled(Button, {
  display: 'flex',
  gap: '0.3rem',
  '& > div': {
    background: 'transparent',
    marginRight: 0,
  },
});

export const EditColumnsWrapper = styled('div', {});
