import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Copy } from '@hexa-ui/icons';
import { TypeToast } from 'admin-portal-shared-services';
import { Toast, ToastTypes, Tooltip } from '@hexa-ui/components';

import * as S from './CopyToClipboard.styles';
import { ICopyToClipboardProps } from 'types/copyToClipboard';

const CopyToClipboard = ({
  hasTooltip = true,
  message,
  string,
}: ICopyToClipboardProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [toastProps, setToastProps] = useState<ToastTypes>({
    type: TypeToast.ERROR,
    open: false,
    position: 'top-right',
    showCloseButton: true,
    onOpenChange: () => false,
    message: '',
  });

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOnOpenChange = (value: boolean) => {
    if (isMounted.current) {
      setToastProps((prevState) => ({
        ...prevState,
        open: value,
      }));
    }
  };

  const handleClose = () => handleOnOpenChange(false);

  const handleToast = (message: string, type: TypeToast) => {
    setToastProps({
      type,
      open: true,
      position: 'top-right',
      showCloseButton: true,
      onOpenChange: handleOnOpenChange,
      message: message,
    });
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      if (!message) {
        return;
      }
      if (isMounted.current) {
        handleToast(message, TypeToast.SUCCESS);
      }
    } catch (error) {
      if (isMounted.current) {
        handleToast(
          formatMessage({ id: 'resolvedRequestsPage.errorsMessages.errorCopyText' }),
          TypeToast.ERROR
        );
      }
    }
  };

  const icon = (
    <Copy size="medium" onClick={() => copyToClipboard(string)} data-testid="copy-icon" />
  );

  return (
    <>
      <Toast.Root {...toastProps} onClose={handleClose} />
      <S.Container data-testid="copy-to-clipboard">
        {hasTooltip ? (
          <Tooltip text={string} placement="bottom">
            <S.IconWrapper>{icon}</S.IconWrapper>
          </Tooltip>
        ) : (
          <S.IconWrapper>{icon}</S.IconWrapper>
        )}
      </S.Container>
    </>
  );
};

export { CopyToClipboard };
