import { useState } from 'react';
import { useMutation } from 'core/hooks';
import { useEnvContext } from 'utils/EnvProvider';
import { deleteScheduleDataMutation } from 'gql/features/reports/mutations';
import { SDeleteVariables } from 'pages/Reports/types/Reports.types';

export function useScheduledDelete(id: string, authorization: string) {
  const { REACT_APP_API_BFF } = useEnvContext();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const scheduleVariablesDelete: SDeleteVariables = {
    input: {
      token: localStorage.getItem('authHeader'),
      id: id,
    },
  };

  const { mutate, error, isLoading } = useMutation({
    key: ['scheduled-reports'],
    graph: `${REACT_APP_API_BFF}`,
    query: deleteScheduleDataMutation,
    variables: scheduleVariablesDelete,
    authorization: `${authorization}`,
    authorizationHub: localStorage.getItem('authHeader'),
  });

  const deleteSchedule = () => {
    mutate(null, {
      onError: () => {
        setIsError(Boolean(error));
      },
      onSuccess: () => {
        setIsDelete(true);
      },
    });
  };

  return { deleteSchedule, isDelete, isError, isLoading };
}
