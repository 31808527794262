import { Button } from '@hexa-ui/components';
import { Calendar, ChevronDown, ChevronUp, Send } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState, useCallback } from 'react';
import * as S from './DropdownButton.styles';
import { handleClickOutside } from './utils';
import { IHandleClickOutsideEvent, ICustomButtonProps } from 'types/dropdownButton';
import { IModalsState } from 'types/requestPage';
import { useFilter } from 'contexts/FilterContext';

/* istanbul ignore next */
const ChevronUpIcon = () => <ChevronUp />;
const ChevronDownIcon = () => <ChevronDown />;

export const DropdownButton: React.FC<ICustomButtonProps> = ({ children, ...props }) => {
  const { formatMessage } = useIntl();
  const buttonRef = useRef(null);
  const submenuRef = useRef(null);
  const [changeIcon, setChangeIcon] = useState(false);
  const { setModals } = useFilter();

  const toggleChangeIcon = () => {
    setChangeIcon((prevState) => !prevState);
  };

  type ModalName = keyof IModalsState;

  const toggleModal = (modalName: ModalName) => {
    setModals((prev: IModalsState) => ({ ...prev, [modalName]: !prev[modalName] }));
  };

  const handleClick = useCallback(
    (event: MouseEvent) => {
      handleClickOutside({
        event: event as IHandleClickOutsideEvent,
        buttonRef,
        submenuRef,
        setChangeIcon,
      });
    },
    [buttonRef, submenuRef, setChangeIcon]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <S.WrapperCustomButton>
      <Button
        {...props}
        ref={buttonRef}
        onClick={toggleChangeIcon}
        icon={changeIcon ? ChevronUpIcon : ChevronDownIcon}
        data-testid="dropdown-button"
      >
        {children}
      </Button>
      {changeIcon && (
        <S.SubmenuWrapper ref={submenuRef}>
          <S.SubmenuItem onClick={() => toggleModal('openSendNowModal')}>
            <Send />
            {formatMessage({ id: 'resolvedRequestsPage.sendNow' })}
          </S.SubmenuItem>
          <S.SubmenuItem onClick={() => toggleModal('openScheduleModal')}>
            <Calendar />
            {formatMessage({ id: 'resolvedRequestsPage.schedule' })}
          </S.SubmenuItem>
        </S.SubmenuWrapper>
      )}
    </S.WrapperCustomButton>
  );
};
