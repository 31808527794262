export const columnsHideShowDropDown = [
  {
    key: 'PAYMENT_ID',
    idName: 'paymentId',
    label: 'Payment ID',
    disabled: true,
  },
  {
    key: 'ORDER_NUMBER',
    idName: 'orderNumber',
    label: 'Order ID',
    disabled: true,
  },
  {
    key: 'ORDER_VALUE',
    idName: 'orderValue',
    label: 'Order value',
    disabled: true,
  },
  {
    key: 'SELLER_ID',
    idName: 'sellerId',
    label: 'Seller ID',
    disabled: false,
  },
  {
    label: 'Invoice date',
    idName: 'invoiceDate',
    key: 'INVOICE_DATE',
    disabled: false,
  },
  {
    key: 'ACCOUNT_ID',
    idName: 'accountId',
    label: 'POC ID',
    disabled: true,
  },
  {
    key: 'INVOICE_NUMBER',
    idName: 'invoiceNumber',
    label: 'Invoice ID',
    disabled: false,
  },
  {
    key: 'PAYMENT_VALUE',
    idName: 'paymentValue',
    label: 'Payment value',
    disabled: false,
  },
  {
    key: 'PAYMENT_STATUS',
    idName: 'paymentStatus',
    label: 'Payment status',
    disabled: true,
  },
  {
    key: 'PAYMENT_METHOD',
    idName: 'paymentMethod',
    label: 'Method',
    disabled: false,
  },
  {
    key: 'PAYMENT_METHOD_CODE',
    idName: 'paymentMethodCode',
    label: 'Service provider',
    disabled: false,
  },
  {
    key: 'ORDER_STATUS',
    idName: 'orderStatus',
    label: 'Order status',
    disabled: false,
  },
  {
    key: 'INVOICE_VALUE',
    idName: 'invoiceValue',
    label: 'Invoice value',
    disabled: true,
  },
  {
    key: 'INVOICE_STATUS',
    idName: 'invoiceStatus',
    label: 'Invoice status',
    disabled: false,
  },
  {
    key: 'PAYMENT_DATE',
    idName: 'paymentDate',
    label: 'Creation date',
    disabled: false,
  },
  {
    key: 'BENEFICIARY_NAME',
    idName: 'beneficiaryName',
    label: 'Beneficiary name',
    disabled: false,
  },
  {
    key: 'BENEFICIARY_ID',
    idName: 'beneficiaryId',
    label: 'Beneficiary ID',
    disabled: false,
  },
  {
    key: 'PAYMENT_METHOD_FEE',
    idName: 'paymentMethodFee',
    label: 'Payment method fee',
    disabled: false,
  },
  {
    key: 'COMMISSION_FEE',
    idName: 'commissionFee',
    label: 'Commission fee',
    disabled: false,
  },
];
