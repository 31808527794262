import {
  isValid,
  parse,
  startOfDay,
  format,
  setMilliseconds,
  setSeconds,
  setMinutes,
  setHours,
  parseISO,
} from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';
import moment from 'moment';
import { ITimeOption } from 'types/date';

const LOCALES = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es,
};

const is24HourLocale = (locale: string): boolean => locale === 'pt-BR' || locale === 'es-419';

const getLocalizedDate = (date: Date, locale: string): string => {
  try {
    return moment(date).locale(locale).format('ll');
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const getLocalizedDateTime = (date: Date, locale: string): string => {
  try {
    return moment(date).locale(locale).format('ll, HH:mm');
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const getLocalizedTime = (time: string, locale: string) => {
  const dateLocale = LOCALES[locale] || enUS; // Fallback to enUS if locale is not supported
  const date = parseISO(`1970-01-01T${time}`);
  const is24HourFormat = is24HourLocale(locale);
  const timeFormat = is24HourFormat ? 'HH:mm' : 'h aaa';
  return format(date, timeFormat, { locale: dateLocale });
};

const formatTimeNumber = (hour: number, language: string): ITimeOption => {
  const locale = LOCALES[language] || enUS;
  const date = setMilliseconds(setSeconds(setMinutes(setHours(new Date(), hour), 0), 0), 0);
  const timeFormat = 'h aaa';

  return {
    id: format(date, 'HH:mm', { locale }),
    name: format(date, timeFormat, { locale }),
    value: format(date, 'HH:mm', { locale }),
    placeholderName: format(date, timeFormat, { locale }),
  };
};

const getFullLocalizedDate = (date: number | Date, location?: string): string => {
  return `${new Intl.DateTimeFormat(location, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date)} at ${new Intl.DateTimeFormat(location, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    hourCycle: 'h12',
  }).format(date)}`;
};

const formatToDateTime = (value: string, lng: string): Date | null => {
  const dateFormats = {
    'pt-BR': 'dd/MM/yyyy',
    'es-419': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
  };

  const dateFormat = dateFormats[lng] ?? dateFormats['en-US'];
  let date = parse(value, dateFormat, new Date());

  if (!isValid(date) && lng === 'en-US') {
    const parts = value.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const newValue = `${month}/${day}/${year}`;
      date = parse(newValue, dateFormat, new Date());
    }
  }

  return isValid(date) ? startOfDay(date) : null;
};

const formatPlaceholderDateInput = (lng: string) => {
  const setPlaceholderDate = {
    'pt-BR': 'DD/MM/AAAA        ',
    'es-419': 'DD/MM/AAAA       ',
    'en-US': 'MM/DD/YYYY        ',
  };
  return setPlaceholderDate[lng] ?? setPlaceholderDate['en-US'];
};

const parseDateString = (dateString: string | undefined, lng: string): Date | null => {
  if (!dateString) {
    return null;
  }

  const dateFormats = {
    'pt-BR': 'dd/MM/yyyy',
    'es-419': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
  };

  const dateFormat = dateFormats[lng] ?? dateFormats['en-US'];
  const parsedDate = parse(dateString, dateFormat, new Date());

  return isValid(parsedDate) ? parsedDate : null;
};

const formatDate = (date: Date, lng: string): string => {
  return format(date, lng === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
};

const formatDateForRequest = (dateString: string, lng: string): string => {
  const parsedDate = parseDateString(dateString, lng);
  if (!parsedDate || !isValid(parsedDate)) {
    return '';
  }
  return format(parsedDate, 'yyyy-MM-dd');
};

export {
  formatPlaceholderDateInput,
  formatToDateTime,
  getFullLocalizedDate,
  getLocalizedDate,
  getLocalizedDateTime,
  getLocalizedTime,
  formatDate,
  parseDateString,
  formatDateForRequest,
  formatTimeNumber,
};
