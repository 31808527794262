import { LoadingBuzz } from '@hexa-ui/components';
import * as S from './Loading.styles';

const Loading = (): JSX.Element => {
  return (
    <S.Container>
      <LoadingBuzz size="xlarge" />
    </S.Container>
  );
};

export default Loading;
