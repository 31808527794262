import { gql } from 'graphql-request';

export const getScheduledCountryQuery = gql`
  query GetSchedulesCountry($input: GetSchedulesCountryInputType!) {
    getSchedulesCountry(input: $input) {
      result {
        id
        emailAddress
        downloadHour
        fileType
        status
        userRequest
        country
        columnFilters
        description
        deleted
        creationDate
        updateDate
        searchFilters {
          buyerId
          invoiceNumber
          invoiceStatus
          orderNumber
          paymentDue
          paymentMethod
          paymentStatus
        }
        downloadDaysOfWeek
      }
    }
  }
`;
