import { useCallback, useContext, useEffect, useState, useRef, forwardRef } from 'react';
import { AlertDialogTitle } from '@radix-ui/react-alert-dialog';
import {
  Divider,
  Heading,
  Pagination,
  Paragraph,
  Toast,
  Modal,
  IconButton,
  LoadingBuzz,
} from '@hexa-ui/components';
import { ChevronDown, ChevronUp, Trash2, X } from '@hexa-ui/icons';

import { ScheduledTrashModal } from './ScheduledTrashModal';
import { useScheduled } from './hooks/useScheduled';
import { useScheduledDelete } from './hooks/useScheduledDelete';
import { formatUTCtime } from './utils';
import * as S from './Scheduled.styles';
import { AuthContext, ModalContext } from 'contexts';
import { changeOverflowAuto } from 'utils/functions';

export const ScheduledModal = forwardRef<HTMLDivElement>((props, ref) => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement | null>(null);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedTrashModalId, setSelectedTrashModalId] = useState<string>('');
  const [emailScheduled, setEmailScheduled] = useState<string>('');
  const [trashedId, setTrashedId] = useState<string>(selectedTrashModalId);
  const [openToast, setOpenToast] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { scheduledData, totalPages, isLoading: isLoadingScheduled } = useScheduled();
  const initPage = (currentPage - 1) * 5;
  const finalPage = currentPage * 5;
  const [scheduledSelectedData, setScheduledSelectedData] = useState(
    scheduledData.slice(initPage, finalPage)
  );
  const { authorization } = useContext(AuthContext);
  const { scheduledModal } = useContext(ModalContext);

  const { deleteSchedule, isDelete, isError } = useScheduledDelete(
    trashedId.toString(),
    authorization
  );

  useEffect(() => {
    setScheduledSelectedData(scheduledData.slice(currentPage - 1, currentPage + 4));
    // TODO: Investigate when adding pagination gives errors
    // TODO: when displaying the last record if using the dependency "currentPage"
  }, [scheduledData]);

  useEffect(() => {
    if (scheduledModal.isOpen) {
      setTimeout(() => {
        firstInteractiveElementRef.current?.focus();
      }, 0);
    }
  }, [scheduledModal.isOpen]);

  const onPaginationChange = (page: number) => {
    setCurrentPage(page);
    setScheduledSelectedData(scheduledData.slice((page - 1) * 5, page * 5));
  };

  const handleSelectedId = (selectedIdItem: number) => {
    if (selectedIdItem === selectedId) {
      setSelectedId(0);
    } else {
      setSelectedId(selectedIdItem);
    }
  };

  const handleOpenTrashModalAndSetEmail = useCallback(
    (idScheduled: string, emailAddress: string) => {
      setSelectedTrashModalId(idScheduled);
      setEmailScheduled(emailAddress);
    },
    []
  );

  const handleCloseTrashModal = () => {
    setSelectedTrashModalId('');
  };

  const handleDeleteItem = () => {
    setTrashedId(String(selectedTrashModalId));
    deleteSchedule();
    setCurrentPage(1);
    setOpenToast(Boolean(selectedTrashModalId));
  };

  const handleOpenInfoToast = () => {
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);
  };

  const renderChevronIcon = (id: number) => (id === selectedId ? <ChevronUp /> : <ChevronDown />);
  const handleClose = () => scheduledModal.setOpen(false);

  changeOverflowAuto();

  return (
    <div ref={ref}>
      <Toast.Provider>
        {isError && (
          <Toast.Root
            type="error"
            open={openToast}
            position="top-right"
            onOpenChange={handleOpenInfoToast}
            message="Has an error occurred while deleting"
          />
        )}
        {isDelete && (
          <Toast.Root
            type="success"
            open={openToast}
            position="top-right"
            onOpenChange={handleOpenInfoToast}
            message="Has been deleted successfully"
          />
        )}
      </Toast.Provider>
      {Boolean(selectedTrashModalId) ? (
        <ScheduledTrashModal
          handleCancelClick={handleCloseTrashModal}
          handleDeleteItem={handleDeleteItem}
          isOpen={Boolean(selectedTrashModalId)}
          emailScheduled={emailScheduled}
        />
      ) : (
        <S.ScheduledModalModalWrapper>
          <Modal.Root
            open={scheduledModal.isOpen}
            actions={null}
            title={
              <S.ScheduledModalTitleWrapper>
                <AlertDialogTitle>Schedule reports</AlertDialogTitle>
                <IconButton
                  ref={firstInteractiveElementRef}
                  onClick={handleClose}
                  size="medium"
                  variant="tertiary"
                  icon={X}
                  tabIndex={0}
                />
              </S.ScheduledModalTitleWrapper>
            }
          >
            <S.ScheduledWrapper>
              {isLoadingScheduled ? (
                <S.WrapperLoading>
                  <LoadingBuzz size="medium" />
                </S.WrapperLoading>
              ) : (
                <>
                  <S.ScheduleModalHeader>
                    <S.ScheduleModalHeaderText>
                      <Paragraph>
                        All downloads that you scheduled. Click in filters to view the selected
                        filters.
                      </Paragraph>
                    </S.ScheduleModalHeaderText>
                  </S.ScheduleModalHeader>
                  <S.ScheduledColumnsContainer>
                    <S.LeftColumnContainer>
                      <S.LeftColumnItem>
                        <Heading size="H5">Frequency</Heading>
                      </S.LeftColumnItem>
                      <S.LeftColumnMidleItem>
                        <Heading size="H5">Delivery time</Heading>
                      </S.LeftColumnMidleItem>
                      <S.LeftColumnRightItem>
                        <Heading size="H5">Email</Heading>
                      </S.LeftColumnRightItem>
                    </S.LeftColumnContainer>
                    <S.RightColumnContainer>
                      <S.RightColumnItem>
                        <Heading size="H5">Country</Heading>
                      </S.RightColumnItem>
                      <S.RightColumnItem>
                        <Heading size="H5">Actions</Heading>
                      </S.RightColumnItem>
                    </S.RightColumnContainer>
                  </S.ScheduledColumnsContainer>
                  {scheduledSelectedData.map((item) => (
                    <S.ScheduledContainer key={item.id}>
                      <S.ScheduledItems>
                        <S.SchChevronItem onClick={() => handleSelectedId(item.id)}>
                          {renderChevronIcon(item.id)}
                        </S.SchChevronItem>
                        <S.SchFrequencyItem>
                          <Paragraph>
                            {item.downloadDaysOfWeek.map((element) => element).join(', ')}
                          </Paragraph>
                        </S.SchFrequencyItem>
                        <S.SchDeliveryTimeItem>
                          <Paragraph>{formatUTCtime(item.downloadHour)}</Paragraph>
                        </S.SchDeliveryTimeItem>
                        <S.SchEmailItem>
                          <Paragraph>{item.emailAddress}</Paragraph>
                        </S.SchEmailItem>
                        <S.SchFileItem>
                          <Paragraph>{item.country}</Paragraph>
                        </S.SchFileItem>
                        <S.SchTrashIcon
                          onClick={() =>
                            handleOpenTrashModalAndSetEmail(item.id, item.emailAddress)
                          }
                        >
                          <Trash2 />
                        </S.SchTrashIcon>
                      </S.ScheduledItems>

                      {item.id === selectedId ? (
                        <S.FilterSumaryContainer>
                          <S.FilterSumaryModal>
                            <Heading size="H5">Filter summary</Heading>
                            {Boolean(item.searchFilters.paymentMethod) && (
                              <S.FilterSumaryItemContainer>
                                <Heading size="H5">Payment method</Heading>
                                <Paragraph>{item.searchFilters.paymentMethod}</Paragraph>
                              </S.FilterSumaryItemContainer>
                            )}
                            {Boolean(item.searchFilters.paymentStatus) && (
                              <S.FilterSumaryItemContainer>
                                <Heading size="H5">Payments status</Heading>
                                <Paragraph>{item.searchFilters.paymentStatus}</Paragraph>
                              </S.FilterSumaryItemContainer>
                            )}
                          </S.FilterSumaryModal>
                          <S.FilterSumaryOrderModal>
                            <S.FilterSumaryItemContainer>
                              <Heading size="H5">Order status</Heading>
                              <Paragraph>{item.status}</Paragraph>
                            </S.FilterSumaryItemContainer>
                            {Boolean(item.searchFilters.paymentDue) && (
                              <S.FilterSumaryItemContainer>
                                <Heading size="H5">Overdue payment</Heading>
                                <Paragraph>{item.searchFilters.paymentDue}</Paragraph>
                              </S.FilterSumaryItemContainer>
                            )}
                          </S.FilterSumaryOrderModal>
                          <S.FilterSumaryOrderModal>
                            {Boolean(item.searchFilters.invoiceStatus) && (
                              <S.FilterSumaryItemContainer>
                                <Heading size="H5">Invoice status</Heading>
                                <Paragraph>{item.searchFilters.invoiceStatus}</Paragraph>
                              </S.FilterSumaryItemContainer>
                            )}
                            {Boolean(item.reconciliation) && (
                              <S.FilterSumaryItemContainer>
                                <Heading size="H5">Reconciliation</Heading>
                                <Paragraph>{item.reconciliation}</Paragraph>
                              </S.FilterSumaryItemContainer>
                            )}
                          </S.FilterSumaryOrderModal>
                        </S.FilterSumaryContainer>
                      ) : (
                        <></>
                      )}
                      <Divider />
                    </S.ScheduledContainer>
                  ))}
                  <S.PaginationContainer>
                    <Pagination
                      total={totalPages}
                      current={currentPage}
                      onChange={onPaginationChange}
                      pageSize={5}
                      showQuantityIndicator={false}
                      showPageSizeSelector={false}
                    />
                  </S.PaginationContainer>
                </>
              )}

              {scheduledData.length === 0 && (
                <S.ScheduledApointmentsFound>No appointments found.</S.ScheduledApointmentsFound>
              )}
            </S.ScheduledWrapper>
          </Modal.Root>
        </S.ScheduledModalModalWrapper>
      )}
    </div>
  );
});
