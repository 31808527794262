import { commonColumns } from 'constants/commonColumns';
import { DEFAULT_PAGE_SIZE } from 'constants/defaults';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ColumnProps } from 'types/editColumns';
import { BaseApproval } from '../../models/Approval';

type Option<T> = {
  idName: string;
  label: string;
  value: T;
};

export interface IFilters {
  filter?: {};
  paymentStatus?: Option<BaseApproval['paymentStatus']>[];
  orderStatus?: Option<BaseApproval['orderStatus']>[];
  sortDirection?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'updatedAt';
  fromCreatedAt?: string;
  toCreatedAt?: string;
  searchLabel?: string;
  searchValue?: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
}

export const isFiltered = (filters: IFilters) => {
  const createdAtFilter = !filters.fromCreatedAt && !filters.toCreatedAt;
  const paymentStatusFilter = (filters?.paymentStatus ?? []).length === 0;
  const orderStatusFilter = (filters?.orderStatus ?? []).length === 0;
  return !(paymentStatusFilter && orderStatusFilter && createdAtFilter);
};
// modal: modals.openScheduleModal, // Added modal property
// setModal: (value: boolean) => setModals((prev) => ({ ...prev, openScheduleModal: value })), // Added setModal property
export interface FilterProps {
  paymentStatusValues: IFilters['paymentStatus'];
  setPaymentStatusValues: React.Dispatch<React.SetStateAction<IFilters['paymentStatus']>>;
  orderStatusValues: IFilters['orderStatus'];
  setOrderStatusValues: React.Dispatch<React.SetStateAction<IFilters['orderStatus']>>;
  fromCreatedAtValue: string;
  setFromCreatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  toCreatedAtValue: string;
  setToCreatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  searchLabel: string;
  setSearchLabel: React.Dispatch<React.SetStateAction<string>>;
  isResetDisabled: boolean;
  isApplyDisabled: (filterValues: IFilters) => boolean;
  initializeFilters: (filterValues: IFilters) => void;
  resetFilters: () => void;
  setColumnsToShow?: React.Dispatch<React.SetStateAction<ColumnProps[]>>;
  modals?: {
    openScheduleModal: boolean;
    openSendNowModal: boolean;
  };
  setModals?: React.Dispatch<
    React.SetStateAction<{
      openScheduleModal: boolean;
      openSendNowModal: boolean;
    }>
  >;
  columnsToShow?: ColumnProps[];
}
interface ProviderProps {
  children: ReactNode;
  initialFilterValues?: IFilters;
}

export const FilterContext = createContext({} as FilterProps);

export const initialValuesRequest: IFilters = {
  filter: {},
  pagination: {
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

export const defaultInitialFilterValues: IFilters = {
  paymentStatus: [],
  orderStatus: [],
  fromCreatedAt: '',
  toCreatedAt: '',
  searchLabel: 'accountId',
  searchValue: '',
};

export const FilterContextProvider = ({
  children,
  initialFilterValues = defaultInitialFilterValues,
}: ProviderProps): JSX.Element => {
  const [paymentStatusValues, setPaymentStatusValues] = useState<IFilters['paymentStatus']>(
    initialFilterValues.paymentStatus ?? []
  );
  const [orderStatusValues, setOrderStatusValues] = useState<IFilters['orderStatus']>(
    initialFilterValues.orderStatus ?? []
  );
  const [fromCreatedAtValue, setFromCreatedAtValue] = useState<IFilters['fromCreatedAt']>(
    initialFilterValues.fromCreatedAt ?? ''
  );
  const [toCreatedAtValue, setToCreatedAtValue] = useState<IFilters['toCreatedAt']>(
    initialFilterValues.toCreatedAt ?? ''
  );
  const [searchLabel, setSearchLabel] = useState<string>(initialFilterValues.searchLabel ?? '');
  const [columnsToShow, setColumnsToShow] = useState<ColumnProps[]>(commonColumns);
  const [modals, setModals] = useState({
    openScheduleModal: false,
    openSendNowModal: false,
  });

  const isResetDisabled = !isFiltered({
    paymentStatus: paymentStatusValues,
    orderStatus: orderStatusValues,
    fromCreatedAt: fromCreatedAtValue,
    toCreatedAt: toCreatedAtValue,
    searchLabel: searchLabel,
  });

  const isApplyDisabled = (filterValues: IFilters) => {
    return (
      JSON.stringify(filterValues.paymentStatus) === JSON.stringify(paymentStatusValues) &&
      JSON.stringify(filterValues.orderStatus) === JSON.stringify(orderStatusValues) &&
      filterValues.fromCreatedAt === fromCreatedAtValue &&
      filterValues.toCreatedAt === toCreatedAtValue
    );
  };

  const initializeFilters = (filterValues: IFilters) => {
    if (!filterValues) {
      console.error('filterValues is null or undefined');
      return;
    }
    setPaymentStatusValues(filterValues.paymentStatus ?? []);
    setOrderStatusValues(filterValues.orderStatus ?? []);
    setFromCreatedAtValue(filterValues.fromCreatedAt ?? '');
    setToCreatedAtValue(filterValues.toCreatedAt ?? '');
    setSearchLabel(filterValues.searchLabel ?? '');
  };

  const resetFilters = () => {
    initializeFilters(defaultInitialFilterValues);
  };

  const value = useMemo(
    () => ({
      paymentStatusValues,
      setPaymentStatusValues,
      orderStatusValues,
      setOrderStatusValues,
      fromCreatedAtValue,
      setFromCreatedAtValue,
      toCreatedAtValue,
      setToCreatedAtValue,
      searchLabel,
      setSearchLabel,
      isResetDisabled,
      isApplyDisabled,
      initializeFilters,
      resetFilters,
      columnsToShow,
      setColumnsToShow,
      modals,
      setModals,
    }),
    [
      setPaymentStatusValues,
      setOrderStatusValues,
      setFromCreatedAtValue,
      setToCreatedAtValue,
      setSearchLabel,
      paymentStatusValues,
      orderStatusValues,
      fromCreatedAtValue,
      toCreatedAtValue,
      searchLabel,
      isResetDisabled,
      isApplyDisabled,
      initializeFilters,
      resetFilters,
      columnsToShow,
      setColumnsToShow,
      modals,
      setModals,
    ]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => useContext(FilterContext);
