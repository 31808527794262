import { IItemCheckboxProps } from 'types/editColumns';
import * as S from './ItemCheckbox.styles';
import { generateCheckboxId } from 'utils/generateUniqueId';

const noop = () => {};

export const ItemCheckbox: React.FC<IItemCheckboxProps> = ({
  label,
  checked,
  onChange = noop,
  disabled = false,
}) => {
  const uniqueId = generateCheckboxId(label);

  return (
    <S.Item className={`${checked ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}>
      <label htmlFor={uniqueId}>
        {label}
        <input
          id={uniqueId}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="checkmark"></span>
      </label>
    </S.Item>
  );
};
