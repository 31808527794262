import { CardWrapperStyle } from './CardWrapper.styles';

import type { TCardWrapperProps } from './CardWrapper.types';

export const CardWrapper = ({
  children,
  elevated = 'small',
  border = 'small',
  ...args
}: TCardWrapperProps): JSX.Element => {
  return (
    <CardWrapperStyle elevated={elevated} border={border} {...args}>
      {children}
    </CardWrapperStyle>
  );
};
