import { useState } from 'react';

import { CreateScheduleProps, DownloadScheduleProps } from 'pages/Reports/types/Reports.types';

export function useModalContext() {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);

  const [handleCreateSchedule, setHandleCreateSchedule] = useState<
    (values: CreateScheduleProps) => void
  >(() => {});

  const [handleSubmitDownload, setHandleSubmitDownload] = useState<
    (values: DownloadScheduleProps) => void
  >(() => {});

  return {
    downloadModal: {
      isOpen: isDownloadModalOpen,
      setOpen: setIsDownloadModalOpen,
      handleSubmitDownload,
      setHandleSubmitDownload,
    },
    scheduleModal: {
      isOpen: isScheduleModalOpen,
      setOpen: setIsScheduleModalOpen,
      handleCreateSchedule,
      setHandleCreateSchedule,
    },
    scheduledModal: {
      isOpen: isScheduledModalOpen,
      setOpen: setIsScheduledModalOpen,
    },
    schedule: {
      isOpen: isScheduleOpen,
      setOpen: setIsScheduleOpen,
    },
  };
}
