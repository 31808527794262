import { useHasPermission } from 'admin-portal-shared-services';
import { ErrorFallback } from 'components/ErrorFallback';
import Loading from 'components/Loading/Loading';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isFeatureEnabled } from 'utils/featureToggles';

export const BASE_PATH = '/bees-gateway-web';
export const REPORTS_PATH = `${BASE_PATH}/payments`;
export const TRANSACTIONS_PATH = `${BASE_PATH}/transactions`;
export const WORKFLOW_PATH = `${BASE_PATH}/workflow`;
export const CREATE_WORKFLOW_PATH = `${BASE_PATH}/workflow/create`;
export const MANAGE_SCHEDULED_REPORTS_PATH = `${TRANSACTIONS_PATH}/manage-scheduled-reports`;
export const RECONCILIATION_PATH = `${BASE_PATH}/reconciliation`;

const Base = lazy(() => import('components/AuthLoadingWrapper'));
const Workflow = lazy(() => import('pages/Workflow'));
const CreateWorkflow = lazy(() => import('pages/CreateWorkflowPage'));
const Reports = lazy(() => import('pages/Reports'));
const RequestPage = lazy(() => import('pages/Reports/RequestPage'));
const ManageScheduledReports = lazy(() => import('pages/ManageScheduledReports'));
const Reconciliation = lazy(() => import('pages/Reconciliation'));

export const AppRoutes = (): JSX.Element => {
  const hasReadWorkflowAuthorization = useHasPermission('GatewayWeb.Workflow.Read');
  const hasWriteWorkflowAuthorization = useHasPermission('GatewayWeb.Workflow.Write');

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={BASE_PATH} element={<Navigate to={REPORTS_PATH} replace />} />
          <Route path={BASE_PATH} element={<Base />}>
            <Route path={REPORTS_PATH} element={<Reports />} />
            {isFeatureEnabled('transactions') && (
              <>
                <Route path={TRANSACTIONS_PATH} element={<RequestPage />} />
                <Route path={MANAGE_SCHEDULED_REPORTS_PATH} element={<ManageScheduledReports />} />
                {isFeatureEnabled('reconciliation') && (
                  <Route path={RECONCILIATION_PATH} element={<Reconciliation />} />
                )}
              </>
            )}
            {isFeatureEnabled('workflow') &&
              (hasReadWorkflowAuthorization || hasWriteWorkflowAuthorization) && (
                <>
                  <Route path={WORKFLOW_PATH} element={<Workflow />} />
                  {hasWriteWorkflowAuthorization && (
                    <Route path={CREATE_WORKFLOW_PATH} element={<CreateWorkflow />} />
                  )}
                </>
              )}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRoutes;
