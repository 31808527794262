import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, FetchResult } from '@apollo/client';
import { ToastTypes } from '@hexa-ui/components';
import { TypeToast, useAppHeader } from 'admin-portal-shared-services';

import { AuthContext } from 'contexts';
import graphql from 'gql';
import scheduleToken from 'utils/decodeToken';
import { useFilter } from 'contexts/FilterContext';
import { IScheduleData } from 'types/schedule';
import { ISendNowVariables } from 'types/sendNowReport';

export const useModalSendNow = (handleCloseModal: () => void) => {
  const { SEND_NOW_REPORT } = graphql.transactions.mutations;
  const { authorization } = useContext(AuthContext);
  const [{ selectedCountry }] = useAppHeader();
  const { columnsToShow = [], orderStatusValues = [], paymentStatusValues = [] } = useFilter();
  const { formatMessage } = useIntl();
  const [isError, setIsError] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastTypes>({
    type: TypeToast.ERROR,
    open: false,
    position: 'top-right',
    onOpenChange: () => false,
    message: '',
  });
  const tokenBeesHub = localStorage.getItem('authHeader');
  const userScheduleToken = scheduleToken();
  const email = userScheduleToken?.email;

  const context = useMemo(
    () => ({
      headers: {
        authorization: authorization ?? '',
        authorizationHub: tokenBeesHub ?? '',
      },
    }),
    [authorization, tokenBeesHub]
  );

  const handleToastOpenChange = (value: boolean) => {
    setToast((prevState) => ({
      ...prevState,
      open: value,
    }));
  };

  const showErrorToast = () => {
    setToast({
      type: TypeToast.ERROR,
      open: true,
      position: 'top-right',
      onOpenChange: handleToastOpenChange,
      message: formatMessage({ id: 'resolvedRequestsPage.reportSendNow.errorSend' }),
    });
  };

  const showSuccessToast = () => {
    setToast({
      type: TypeToast.SUCCESS,
      open: true,
      position: 'top-right',
      onOpenChange: handleToastOpenChange,
      message: formatMessage({ id: 'resolvedRequestsPage.reportSendNow.successSend' }),
    });
  };

  const orderStatus = orderStatusValues.map((status) => status.value);
  const paymentStatus = paymentStatusValues.map((status) => status.value);
  const fieldsToExport = columnsToShow.map((column) => column.key);

  const [mutateFunction, { loading }] = useMutation(SEND_NOW_REPORT, {
    context,
    onCompleted: () => {
      showSuccessToast();
      handleCloseModal();
    },
    onError: (error) => {
      setIsError(true);
      console.error('Error creating schedule:', error);
      showErrorToast();
    },
  });

  const FILE_TYPE = 'CSV';

  const createSchedule = (): Promise<FetchResult<IScheduleData>> => {
    const variables: ISendNowVariables = {
      input: {
        fieldsToExport,
        emailAddress: email,
        country: selectedCountry,
        token: '',
        fileType: FILE_TYPE,
        paymentStatus: paymentStatus.length ? paymentStatus : undefined,
        orderStatus: orderStatus.length ? orderStatus : undefined,
      },
    };

    return mutateFunction({ variables });
  };

  return { createSchedule, loading, isError, toast, handleToastOpenChange, setToast };
};
